import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TablePagination, TableSortLabel, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar, TextField } from '@material-ui/core';
import { Link } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';

import { DeleteProduct } from '.././Store';
import { GetImagePath } from '.././Utils';

import { ShakiContext } from "../store/ShakiProvider";

function Products() {

	const { products, getRemoteConf, getArticlesByCategory } = useContext(ShakiContext);

	const title = 'Produits';
	const classes = useStyles();

	const [filter, setFilter] = useState('');
	const [list, setList] = useState([]);
	const [sort, setSort] = useState('asc');
	const [orderBy, setOrderBy] = useState('sku');
	const [packaging_types, setPackagingTypes] = useState({});
	const [articles, setArticles] = useState({});

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);


	useEffect(() => {

		var a = [];

		for (var i in products) {

			var product = products[i];
			if (product.sub_recipe) continue;
			if (product.disabled === true) continue;
			if (filter !== '' && product.search_index.toLowerCase().search(filter.toLowerCase()) === -1) continue;

			product.key_sort = (product.parent_sku === undefined ? product.sku : product.parent_sku) + (product.parent_sku === undefined ? 0 : 1)
			a.push(product);
		};

		setList(a);

	}, [products, filter]);


	useEffect(() => {
		setPackagingTypes(getRemoteConf('packaging_types'));
	}, []);


	useEffect(() => {

		getArticlesByCategory('Emballage', (list) => {
			var hash = {};
			for (var article of list) {
				hash[article.id] = article;
			};
			setArticles(hash);
		});

	}, []);


	var del = (e) => {
		DeleteProduct(e);
	};


	return (
		<div>

			<div style={{ padding: 20 }}>

				<Breadcrumbs aria-label="breadcrumb">
					<Link to='/'>Dashboard</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>

				<h2>{title}</h2>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div />
					<div style={{ alignItems: 'center' }}>
						<TextField
							style={{ width: 200 }}
							placeholder="Filtrer les produits"
							onChange={(e) => setFilter(e.target.value)}
						/>
					</div>
				</div>
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell><b>SKU</b></StyledTableCell>
							<StyledTableCell><b>EAN13</b></StyledTableCell>
							<StyledTableCell><b>Marque</b></StyledTableCell>
							<StyledTableCell><b>Nom</b></StyledTableCell>
							<StyledTableCell><b>Conditionnement</b></StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{list.sort((a, b) => a.key_sort > b.key_sort ? 1 : -1).map((row, i) => (
						<StyledTableRow key={row.sku}>

							<StyledTableCell >
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }} >
									{row.parent_sku !== undefined ?
										<div style={{ width: 30, height: 70, opacity: 0.5, marginRight: 2, borderRadius: 5 }} />
										:
										<div style={{ width: 5, height: 70, backgroundColor: '#AEEE95', opacity: 0.8, marginRight: 2, borderRadius: 5 }} />
									}
									<Avatar src={GetImagePath(row.images, 'main')} className={classes.large} style={{ width: 105, height: 70 }} variant='rounded' component='span'/>
								</div>
							</StyledTableCell>

							<StyledTableCell component="th" scope="row">

								<Link to={'/products/' + row.id}>
									{row.sku}
								</Link>

								<br />
								<div style={{ fontSize: 12 }}>{row.parent_sku}</div>

							</StyledTableCell>

							<StyledTableCell>{row.ean13}</StyledTableCell>

							<StyledTableCell>
								{row.disabled ? '🔴' : '' } {row.brand}
								<br/>
								<div style={{ fontSize: 12 }}>{row.category}</div>
							</StyledTableCell>

							<StyledTableCell>
								{row.name}<br />
								<div style={{ fontSize: 12 }}>{row.weight}g</div>
							</StyledTableCell>

							<StyledTableCell>
								{(Object.keys(articles).length > 0 && row.packaging !== undefined && row.packaging.articles !== undefined && row.packaging.articles.length > 0) &&
									<span>
										{row.packaging.articles.sort((a, b) => articles[a].name.localeCompare(articles[b].name)).map((article) => articles[article].name).join(', ')}
									</span>
								}<br/>
								{row.packaging !== undefined && row.packaging.format !== '' ? 'Avant : ' + row.packaging.format : '-'}<br/>
								{row.packaging !== undefined && row.packaging.back_format !== '' ? 'Arrière : ' + row.packaging.back_format : '-'}
							</StyledTableCell>

						</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[50, 100, 200]}
				component="div"
				count={list.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(event) => {
					setRowsPerPage(parseInt(event.target.value, 10));
					setPage(0);
				}}
			/>
		</div>
	);
};

export default React.memo(Products);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	large: {
		width: 80,
		height: 80,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
});
