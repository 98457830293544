import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { InputAdornment, Checkbox, Paper, TextField, Typography, Breadcrumbs, Button, Avatar, Tabs, Tab } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";

import Resizer from 'react-image-file-resizer';
import { useForm, Controller } from "react-hook-form";

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';


import { ShakiContext } from "../store/ShakiProvider";
import { UsersContext } from '../store/UsersProvider';
import { ClientsContext } from "../store/ClientsProvider";
import { ShopsContext } from "../store/ShopsProvider";

import { UploadImage, GetImagePath } from '.././Utils';
import ClientHeader from './ClientHeader';


function Client() {

	const { setOpenSnackBar, getRemoteConf } = useContext(ShakiContext);
	const { checkUserRights } = useContext(UsersContext);
	const { getClient, createClient, updateClient } = useContext(ClientsContext);
	const { price_list, price_list_groups } = useContext(ShopsContext);

	var { client_id } = useParams();

	var history = useHistory();
	const classes = useStyles();


	const [current_client, setCurrentClient] = useState({});
	const [tags, setTags] = useState([]);
	const [tab_value, setTabValue] = useState(0);

	var action = client_id === 'new' ? 'create' : 'update';
	var title = (current_client.name !== undefined) ? current_client.name : 'Nouveau';

	const { register, handleSubmit, errors, control, reset, watch } = useForm({
		defaultValues: current_client
	});


	useEffect(() => {
		setTags(getRemoteConf('tags'));
	}, []);


	useEffect(() => {

		if (tags.length === 0) return;
		if (client_id === 'new') return;

		getClient(client_id, (doc) => {

			var data = doc.data();

			var default_tags = [];
			for (var i in data.tags) {
				default_tags.push(
					tags.find((element) => element.key === data.tags[i])
				);
			};
			data.tags = default_tags;

			reset(data);
			setCurrentClient(data);
		});

	}, [client_id, tags]);


	//const [picture, setPicture] = useState(null);
	const fileChangedHandler = (event) => {

		if (event.target.files[0]) {

			Resizer.imageFileResizer(event.target.files[0], 1000, 1000, 'JPEG', 90, 0, (file) => {

				UploadImage('clients', 'main', current_client.id, file, (path) => {
					setOpenSnackBar(true);
				});

			}, 'file');
		};
	};


	const onSubmit = (raw_data) => {

		var data = JSON.parse(JSON.stringify(raw_data));

		if (action === 'update') delete data.key;

		var new_tags = [];
		for (var i in data.tags) {
			new_tags.push(data.tags[i].key);
		};
		data.tags = new_tags;

		if (data.invoicing.holding !== undefined && data.invoicing.holding === '') delete data.invoicing.holding;

		if (action === 'create') {

			createClient(data, (id) => {
				setOpenSnackBar(true);
				history.push('/clients/' + id);
			});

		} else {

			updateClient(current_client.id, data, () => {
				setOpenSnackBar(true);
			});
		};
	};


    if ((action === 'update' && Object.keys(current_client).length === 0)) {
		return null;
	};


	if (Object.values(price_list).length === 0) {
		return null;
	};


	return (
		<div>

			<ClientHeader client_id={client_id} tab_value={tab_value} />

			<div id='tab_0' role='tabpanel' hidden={tab_value !== 0} style={{ width: 700, marginBottom: 45, padding: 20 }}>

				<form onSubmit={handleSubmit(onSubmit)}>

					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Informations</b>
						</Typography>

						<div style={{ display: 'flex', marginBottom: 15 }}>

							<label htmlFor='picture' style={{ marginRight: 10 }}>
								<input id='picture' type='file' onChange={fileChangedHandler} style={{ display: 'none' }} />
								<Avatar src={GetImagePath(current_client.images, 'main')} style={{ width: 50, height: 50 }} component='span' />
							</label>

							<TextField
								required
								inputRef={register()}
								name='legal_entity'
								label='Entité juridique'
								style={{ width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								required
								inputRef={register({
									pattern : {
										value: /^([0-9]{3}) ([0-9]{3}) ([0-9]{3})$/,
										message: "Siren invalide, format attendu 123 456 789"
									}
								})}
								name='siren'
								label='Siren'
								style={{ width: '33%' }}
								error={(errors.contact_accounting !== undefined && errors.contact_accounting.siren) ? true : false}
							/>
							<TextField
								inputRef={register({
									pattern : {
										value: /^([0-9]{3}) ([0-9]{3}) ([0-9]{3}) ([0-9]{5})$/,
										message: "Siret invalide, format attendu 123 456 789 00000"
									}
								})}
								name='siret'
								label='Siret'
								style={{ width: '33%', marginLeft: 10 }}
								error={(errors.siret) ? true : false}
							/>
							<TextField
								inputRef={register({
									pattern : {
										value: /^FR([0-9]{2})([0-9]{9})$/,
										message: "TVA invalide"
									}
								})}
								name='vat'
								label='TVA'
								style={{ width: '33%', marginLeft: 10 }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								required
								inputRef={register()}
								name='address'
								label='Adresse'
								style={{ width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>

							<TextField
								required
								inputRef={register()}
								name='city'
								label='Ville'
								style={{ width: '60%' }}
							/>
							<TextField
								required
								inputRef={register()}
								name='zip'
								label='Code postal'
								style={{ width: '15%', marginLeft: 10 }}
							/>
							<TextField
								required
								inputRef={register()}
								name='country'
								label='Pays'
								style={{ width: '25%', marginLeft: 10 }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								inputRef={register({
									pattern : {
										value: /^(\+33)[1-2-3-4-5]([-.: ]?[0-9]{2}){4}$/,
										message: "Numéro de téléphone invalide"
									}
								})}
								name='phone_number'
								label='Téléphone'
								style={{ width: '50%' }}
								error={(errors.tel) ? true : false}
							/>
							<TextField
								inputRef={register({
									pattern : {
										value: /^(\+33)[6-7]([-.: ]?[0-9]{2}){4}$/,
										message: "Numéro de téléphone invalide"
									}
								})}
								name='mobile_number'
								label='Téléphone portable'
								style={{ width: '50%', marginLeft : 10 }}
								error={(errors.mobile) ? true : false}
							/>

						</div>
						<div style={{ display: 'flex', marginBottom: 10 }}>
							<TextField
								required
								inputRef={register({
									validate: (value) => {
										for (var v of value.split(',')) {
											if (v.trim().match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) === null) return 'Email invalide';
										};
										return true
									}
								})}
								name='email'
								label='Email'
								style={{width: '100%'}}
								error={ errors.email ? true : false }
							/>
						</div>
						<div style={{ display: 'flex', marginBottom: 10 }}>
							<TextField
								required
								inputRef={register({
									validate: (value) => {
										for (var v of value.split(',')) {
											if (v.trim().match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) === null) return 'Email invalide';
										};
										return true
									}
								})}
								name='reminder_email'
								label='Email Relance'
								style={{ width: '100%' }}
								error={ errors.reminder_email ? true : false }
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>

							<FormControl className={classes.formControl} style={{ width: '30%', marginRight: 10 }}>
								<InputLabel htmlFor='bank_balance_group' shrink>Regroupement bancaire</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'bank_balance_group'
									}}
								>
									<option value=''></option>
									{Object.values(tags).filter((element) => element.type === 'client').sort((a, b) => a.title > b.title ? 1 : -1).map((row, index) => (
										<option key={'bank_balance_' + row.key} value={row.key}>{row.title}</option>
									))}
								</Select>
							</FormControl>

							<TextField
								inputRef={register({})}
								name='bank_informations_matching'
								label='Informations bancaires'
								style={{ width: '70%' }}
							/>
						</div>
					</div>


					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Tags</b>
						</Typography>

						{tags.length > 0 &&
							<div style={{ display: 'flex', marginBottom: 10 }}>

								<Controller
									render={props => (
										<Autocomplete
											{...props}

											style={{ width: '100%' }}
											multiple
											id='tags-standard'
											options={tags}
											getOptionLabel={(option) => option.title}
											getOptionSelected={(option, value) => option.key === value.key}

											onChange={(_, data) => props.onChange(data)}

											filterSelectedOptions={true}

											renderInput={(params) => (
												<TextField
													{...params}
													variant='standard'
													label=''
													placeholder="Choisissez un ou plusieurs tags"
												/>
											)}
										/>
									)}
									name='tags'
									control={control}
								/>

							</div>
						}
					</div>


					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Paramétrages facturation</b>
						</Typography>

						{(current_client.invoicing === undefined || current_client.invoicing.holding === undefined || current_client.invoicing.holding === '') &&
						<div>
							<div style={{display: 'flex', paddingBottom: 15 }}>

								<FormControl className={classes.formControl} style={{ width: 200 }}>
									<InputLabel htmlFor='invoicing_frequency' shrink>Fréquence de facturation</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'invoicing.frequency',
											id: 'invoicing_frequency',
										}}
									>
										<option value='order'>Facture à la commande</option>
										<option value='weekly'>Facture hebdomadaire</option>
										<option value='monthly'>Facture mensuelle</option>
										<option value='no_invoice'>Pas de facturation</option>
									</Select>
								</FormControl>

								<FormControl className={classes.formControl} style={{ width : 200, marginLeft: 10 }}>
									<InputLabel htmlFor='payment_period' shrink>Délai de paiement</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'invoicing.payment_period',
											id: 'invoicing_payment_period',
										}}
									>
										<option value='0'>A réception</option>
										<option value='10'>10 jours</option>
										<option value='30'>30 jours</option>
										<option value='45'>45 jours</option>
									</Select>
								</FormControl>

							</div>

							<div style={{ display: 'flex', paddingBottom: 15 }}>

								<TextField
									inputRef={register({})}
									name='invoicing.provider_code'
									label='Code Fournisseur'
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: 200 }}
								/>

								<TextField
									inputRef={register({})}
									name='invoicing.gold_code'
									label='Code Gold'
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: 200, marginLeft: 10 }}
								/>

							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: 200 }}>
									<InputLabel htmlFor='pricelist_group_id' shrink>Groupe de prix associé</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'pricelist_group_id',
											id: 'pricelist_group_id',
										}}
									>
										<option value=''>---</option>
										{Object.values(price_list_groups).map((row, index) => (
											<option key={'pricelist_group_id_' + index} value={row.id}>{row.name}</option>
										))}
									</Select>
								</FormControl>
							</div>

							<div>
								<Controller
									style={{ marginLeft: 5 }}
									control={control}
									name={'invoicing.factoring'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label="Activer l'affacturage"
											style={{ width: 300 }}
										/>
									}
								/>
							</div>

							<div>
								<Controller
									control={control}
									name={'invoicing.postal'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label="Envoyer la facture par courrier"
											style={{ width: 300 }}
										/>
									}
								/>
							</div>
						</div>
						}

					</div>

					<div style={{ position: 'fixed', bottom: 0, left: 200, width: '100%', backgroundColor: 'white', padding: 24, borderTop: 'solid rgba(0, 0, 0, 0.12) 1px' }}>
						<Button disabled={!checkUserRights('clients_write')} variant='contained' color="default" type='submit'>
							Enregistrer
						</Button>
					</div>

				</form>

			</div>

		</div>
	);
}

export default React.memo(Client);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	}
});
