import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { Link, useParams, useHistory } from "react-router-dom";
import { TextField, Typography, Breadcrumbs, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { Radio, RadioGroup } from "@material-ui/core";

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";

import ProductForm from './ProductForm';

import { AddOrder, UpdateOrder } from '.././Store';
import { DateTime } from "luxon";

import { getRoutesConfs } from '.././Store';
import { GetTotalPrices } from '.././Utils';


function CreateOrder() {

	const { products_by_sku, setOpenSnackBar, getRemoteConf } = useContext(ShakiContext);
	const { user, isAdmin } = useContext(UsersContext);
	const { price_list, shops, GetCurrentPriceList, GetCurrentRateCard, GetShop } = useContext(ShopsContext);

	var { order_id, shop_id } = useParams();
	const action = order_id === undefined ? 'create' : 'update';

	var history = useHistory();

	var prices = {};
	var default_values = {};

	const today = DateTime.local();

	var next_delivery_day = today.plus({ days: 2 });
	if (next_delivery_day.weekday === 7) next_delivery_day = today.plus({ days: 3 });

	const [items, setItems] = useState([]);

	const [available_tours, setAvailableTours] = useState([]);

	const [tours, setTours] = useState([]);
	const [tours_hash, setToursHash] = useState({});
	const [eligible_delivery_days, setEligibleDeliveryDays] = useState([]);

	const [order, setOrder] = useState({});
	const [current_shop, setCurrentShop] = useState(null);
	const [current_price_list, setCurrentPricelist] = useState({});
	const [current_rate_card, setCurrentRateCard] = useState({});
	const [container_types, setContainerTypes] = useState({});
	const [working_hours, setWorkingHours] = useState({});


	const { register, handleSubmit, watch, control, reset, setValue} = useForm({
		defaultValues : default_values
	});

	const { } = useFieldArray({
		control,
		name: 'delivery'
	});

	var display_national_direct = watch('delivery_national');


	useEffect(() => {
		setContainerTypes(getRemoteConf('container_types'));
		setWorkingHours(getRemoteConf('working_hours'));
	}, []);


	useEffect(() => {

		if (order_id !== undefined) return;
		if (shop_id === undefined) return;

		// Create case

		GetShop(shop_id, (doc) => {
			var data = doc.data();
			setCurrentShop(data);
		}, false);

	}, [order_id, shop_id]);


	useEffect(() => {

		if (shop_id !== undefined) return;
		if (Object.values(order).length === 0) return;

		// Update case
		GetShop(order.shop_id, (doc) => {
			var data = doc.data();
			setCurrentShop(data);
		}, false);

	}, [shop_id, order]);


	useEffect(() => {

		if (current_shop === null) return;
		if (order_id !== undefined) return;

		// Create case
		var order = {
			client: {
				address: current_shop.address,
				address_infos: current_shop.address_infos,
				city: current_shop.city,
				cp: current_shop.cp,
				contact_name: current_shop.contact_name,
				tel: (current_shop.tel === undefined || current_shop.tel === '') ? current_shop.mobile : current_shop.tel
			},
			external_reference: '',
			key: Math.random().toString(20).substr(2, 12),
			day: next_delivery_day,
			container_type: current_shop.container_type,
			delivery: current_shop.delivery,
			delivery_national: current_shop.delivery_national,
			delivery_national_direct: current_shop.delivery_national_direct,
			delivery_via_pallet : current_shop.delivery_via_pallet,

			delivery_signature: current_shop.delivery_signature,
			delivery_picture: current_shop.delivery_picture,
			delivery_picture_library: current_shop.delivery_picture_library,
			delivery_unsold: current_shop.delivery_unsold,
			delivery_hour: current_shop.delivery_hour,
			preparation_hour: current_shop.preparation_hour,
			products: {}
		};

		reset(order);
		setOrder(order);

	}, [order_id, current_shop]);


	useEffect(() => {

		if (order_id === undefined) return;

		return firebase.firestore().collection('shaki_orders').doc(order_id).get().then((doc) => {

			var order = doc.data();
			//order.shift = String(order.shift)

			var d = DateTime.fromISO(order.day);

			reset(order);
			setOrder(order);
			setValue('day', d);
		});

	}, [order_id]);


	useEffect(() => {

		if (Object.values(order).length === 0) return;
		if (current_shop === null) return;
		if (Object.values(price_list).length === 0) return;

		var d = DateTime.fromISO(order.day);

		setCurrentRateCard(GetCurrentRateCard(parseInt(d.toFormat('yyyyLLdd'))));

		if (current_shop.pricelist_group_id !== undefined) {
			setCurrentPricelist(GetCurrentPriceList(current_shop.pricelist_group_id, parseInt(d.toFormat('yyyyLLdd'))));
		};

	}, [order, price_list, current_shop]);


	var delivery_day = useWatch({ control, name: 'day' })
	useEffect(() => {

		if (tours.length === 0) return;
		if (delivery_day === undefined) return;
		if (current_shop.pricelist_group_id === undefined) return;

		if (!Number.isInteger(delivery_day)) {
			setCurrentPricelist(GetCurrentPriceList(current_shop.pricelist_group_id, parseInt(delivery_day.toFormat('yyyyLLdd'))));
		};
		getAvailableTours(delivery_day);

	}, [delivery_day, tours]);


	const getAvailableTours = (delivery_day) => {

		var list = [];
		for (var i in tours) {
			if (tours[i].day === delivery_day.weekday - (current_shop.delivery_national_direct === true ? 1 : 0)) {
				list.push(tours[i]);
			}
		}

		setAvailableTours(list);
	};


	useEffect(() => {

		if (current_shop === null) return;

		return getRoutesConfs((docs) => {

			var c = [];
			var d = [];
			var h = {};
			docs.forEach((doc) => {

				var shift = doc.data();

				for (var i in shift.list) {

					if (current_shop.delivery_national_direct !== true && shift.list[i].shop_id === current_shop.id) {
						c.push(shift);
						d.push(shift.day)
					};

					if (current_shop.delivery_national_direct === true && shift.list[i].shop_id === 'M3C26aarYXsZ4uEAKwoO') {
						c.push(shift);
						d.push(shift.day + 1)
					};

				}
				h[doc.id] = shift;
			});

			setTours(c);
			setToursHash(h);
			setEligibleDeliveryDays(d);
		})

	}, [current_shop]);


	const getProductionDay = (selected_tour, delivery_day) => {

		var day = tours_hash[selected_tour].day;
		var pickup_hour_arr = tours_hash[selected_tour].pickup_hour.split(':');
		var pickup_hour = parseInt(pickup_hour_arr[0]) + parseInt(pickup_hour_arr[1]) / 100;

		if (delivery_day.weekday === day) {
			for (var j in working_hours[day]) {
				if (working_hours[day][j].end < pickup_hour) {
					return delivery_day;
				};
			};
		};

		return delivery_day.minus({ days: 1 });
	};


	var selected_tour = watch('tour');

	/*
	var available_shifts = getAvailableShifts(selected_tour);
	const getAvailableShifts = (selected_tour) => {

		if (selected_tour === undefined) return []
		if (Object.values(tours_hash).length === 0) return [];
		if (tours_hash[selected_tour] === undefined) return [];

		var day_prod = null;
		var shift = null;

		var day = tours_hash[selected_tour].day;
		var pickup_hour_arr = tours_hash[selected_tour].pickup_hour.split(':');
		var pickup_hour = parseInt(pickup_hour_arr[0]) + parseInt(pickup_hour_arr[1])/100

		for (var j in working_hours[day]) {
			if (working_hours[day][j].end < pickup_hour) {
				day_prod = day;
				shift = parseInt(j) + 1;
			}
		}

		if (day_prod === null) {

			if ((day - 1) === 0) {
				day = 7
			} else {
				day = day - 1;
			}

			shift = working_hours[day].length;
		}

		// Si national, forcement le chiffre 1
		if (current_shop.delivery_national) {
			shift = 1;
		}

		var list = [];
		for (var i = 0; i < shift; i++) {
			list.push(i);
		}

		return list;
	};
	*/


	const onSubmit = (data) => {

		for (var sku in data.products) {

			if (parseInt(data.products[sku]) === 0 || data.products[sku] === '') {
				delete data.products[sku];
				continue;
			};

			data.products[sku] = parseInt(data.products[sku]);
		};

		const delivery_day = DateTime.fromISO(data.day);
		var production_day = getProductionDay(data.tour, delivery_day); // delivery_day.minus({days : 1});

		var obj = {...data}
		//obj.shift = parseInt(obj.shift);
		obj.day = parseInt(delivery_day.toFormat('yyyyLLdd'));
		obj.day_prod = parseInt(production_day.toFormat('yyyyLLdd'));
		obj.shop_id = current_shop.id;
		obj.predictive = false;

		if (action === 'create') {

			AddOrder(obj, user, () => {
				history.push('/shops/' + current_shop.id);
				setOpenSnackBar('Sauvegardé')
			});

		} else {

			UpdateOrder(order_id, obj, user, () => {
				history.push('/shops/' + current_shop.id);
				setOpenSnackBar('Sauvegardé')
			});
		};

		return true;
	};


	const displayDate = (date) => {
		return !eligible_delivery_days.includes(parseInt(date.toFormat('c')));
	};


	const getTotalProducts = (products) => {
		var total = 0;
		for (var sku in products) {
			total += parseInt(products[sku]);
		};
		return total;
	};


	const Brand = (row) => {
		return (
			<div style={{ backgroundColor: '#000', color: '#fff', padding: 5, paddingLeft: 10, marginTop: 20, width: 700, borderRadius: 10 }}>
				{row.category}
			</div>
		);
	};


	const Category = (row) => {
		return (
			<div style={{ borderBottom: '1px solid black', margin: 10, marginTop: 20, marginBottom: 15 }}>
				{row.category}
			</div>
		);
	};


	////////////////////
	// Calcul Pricing //
	////////////////////

	const product_fields = useWatch({
		control,
		name: 'products',
		defaultValue: {}
	});
	var computed_price = GetTotalPrices(current_rate_card, current_price_list, product_fields, 'object');
	var total_products = getTotalProducts(product_fields);

	useEffect(() => {

		if (Object.values(current_price_list).length === 0) return;
		if (Object.values(products_by_sku).length === 0) return;

		var recipes = {};

		for (var sku in current_price_list.prices) {

			if (current_price_list.prices[sku].enabled === undefined || current_price_list.prices[sku].enabled === false) continue;
			if (products_by_sku[sku] === undefined) continue;
			if (products_by_sku[sku].disabled === true) continue;

			var brand = (products_by_sku[sku].brand !== undefined) ? products_by_sku[sku].brand : 'Autres';
			if (recipes[brand] === undefined) recipes[brand] = {}

			var category = (products_by_sku[sku].category !== undefined) ? products_by_sku[sku].category : 'Autres';
			if (recipes[brand][category] === undefined) recipes[brand][category] = {};

			recipes[brand][category][sku] = current_price_list.prices[sku];
		}

		var sort_brand = Object.entries(recipes).sort((a, b) => {
			if (a[0].substring(2) < b[0].substring(2)) return -1;
			if (a[0].substring(2) > b[0].substring(2)) return 1;
			return 0;
		});

	 	var items = [];
	 	for (var i in sort_brand) {

			items.push(<Brand key={i} category={sort_brand[i][0]} />);

			var sort_category = Object.entries(sort_brand[i][1]).sort();
			for (var j in sort_category) {

				items.push(<Category key={i + '_' + j} category={sort_category[j][0]} />);

				var sort_sku = Object.entries(sort_category[j][1]).sort();
				for (var k in sort_sku) {

					items.push(<ProductForm key={i + '_' + j + '_' + k} register={register} control={control} order={order} {...sort_sku[k][1]} rate_card={current_rate_card} pricelist={current_price_list} />);
				}
			}
		}

		setItems(items);

	}, [current_price_list, products_by_sku]);


	return (

		<form onSubmit={handleSubmit(onSubmit)}>

		<div style={{ width: 700, padding: 20 }}>

			<Breadcrumbs aria-label="breadcrumb">
				{isAdmin() &&
					<Link to='/'>Accueil</Link>
				}
				{current_shop !== null &&
					<Link to={'/shops/' + current_shop.id}>{current_shop.name}</Link>
				}
				{order !== null &&
					<Typography>{order.key}</Typography>
				}
			</Breadcrumbs>

			{action === 'create' &&
				<h2 style={{ paddingBottom: 40 }}>Nouvelle commande</h2>
			}

			{action === 'update' &&
				<h2 style={{ paddingBottom: 40 }}>Mise à jour</h2>
			}

			<div>

				<div style={{ paddingBottom: 40 }}>
					<Controller as={TextField}
						control={control}
						name='key'
						label='Réference Luna'
						defaultValue=''
						style={{ width: 150 }}
					/>
					<Controller as={TextField}
						control={control}
						name='external_reference'
						label='Réference externe'
						defaultValue=''
						style={{ width: 150, marginLeft: 15 }}
					/>
				</div>

				<div style={{ paddingBottom: 40 }}>

					<Typography variant="h6" noWrap><b>Quand souhaitez-vous être livré ?</b></Typography>

					<MuiPickersUtilsProvider utils={LuxonUtils}>
					<Controller
						as={
							<KeyboardDatePicker
								disableToolbar
								format='dd/MM/yyyy'
								margin='normal'
								label='Jour de livraison'
								onChange={(date) => {console.log(date)}}
								shouldDisableDate={(date) => {return displayDate(date);}}
								disablePast={true}
								KeyboardButtonProps={{
									'aria-label': 'change date'
								}}
							/>
						}
						control={control}
						name='day'
						style={{ width: 200 }}
					/>
					</MuiPickersUtilsProvider>
				</div>

				<div style={{ paddingBottom: 40 }}>

					{available_tours.length > 0 &&
					<>
					<Typography variant="h6" noWrap><b>Choix de la tournée</b></Typography>

					<Controller
						rules={{ required: true }}
						name='tour'
						control={control}
						as={
						<RadioGroup>
							{available_tours.map((row, index) => (
							<FormControlLabel
								key={row.id}
								value={row.id}
								control={<Radio />}
								label={'Départ à ' + row.pickup_hour +' (' + row.name + ' ' + row.day + ') '}
							/>
							))}
						</RadioGroup>
						}
					/>
					</>
					}

				</div>


				{/*
				<div style={{ paddingBottom: 40 }}>

					{available_shifts.length > 0 &&
					<>
					<Typography variant="h6" noWrap><b>Choix du shift de production</b></Typography>

					<Controller
						rules={{ required: true }}
						name='shift'
						control={control}
						as={
						<RadioGroup>
							{available_shifts.map((row, index) => (
							<FormControlLabel
								key={'shift_' + index}
								value={String(index + 1)}
								control={<Radio />}
								label={'Shift ' + (index + 1)}
							/>
							))}
						</RadioGroup>
						}
					/>
					</>
					}
				</div>
				*/}


				<div style={{ paddingBottom : 40, display: (user.admin ? 'block' : 'none') }}>

					<Typography variant="h6" noWrap><b>Adresse de livraison</b></Typography>

					<div style={{display : 'flex', paddingBottom : 10}}>
						<Controller as={TextField}
							control={control}
							name='client.contact_name'
							label='Personne à contacter'
							defaultValue=''
							style={{width: '50%'}}
						/>
						<Controller as={TextField}
							control={control}
							name='client.tel'
							label='Numéro de téléphone'
							defaultValue=''
							style={{ width: '50%', marginLeft: 10 }}
						/>
					</div>

					<div style={{ paddingBottom: 10 }}>
						<Controller as={TextField}
							control={control}
							name='client.address'
							label='Adresse'
							defaultValue=''
							style={{ width: '100%' }}
						/>
					</div>

					<div style={{ paddingBottom: 10 }}>
						<Controller as={TextField}
							control={control}
							name='client.address_infos'
							label='Compléments adresse'
							defaultValue=''
							style={{width: '100%'}}
						/>
					</div>

					<div>
						<Controller as={TextField}
							control={control}
							name='client.city'
							label='Ville'
							defaultValue=''
							style={{width: '30%'}}
						/>
						<Controller as={TextField}
							control={control}
							name='client.cp'
							label='Code Postal'
							defaultValue=''
							style={{width: '30%', marginLeft : 10}}
						/>
					</div>

				</div>

				<div style={{ paddingBottom: 40 }}>

					<Typography variant="h6" noWrap style={{paddingBottom: 20}}>
						<b>Paramétrages livraison</b>
					</Typography>

					<div style={{ display: 'flex', marginBottom: 15 }}>
						<FormControl style={{ width : '33%' }}>
							<InputLabel htmlFor='container_type'>Contenant</InputLabel>
							<Select
								inputRef={register()}
								native
								inputProps={{
									name: 'container_type',
									id: 'container_type',
									shrink: true,
								}}
							>
								{Object.entries(container_types).map((row, index) => (
									<option key={row[0]} value={row[0]}>{row[1].name}</option>
								))}
							</Select>
						</FormControl>
					</div>

					<div>
						<Controller
							control={control}
							name={'delivery_via_pallet'}
							defaultValue={false}
							render={props =>
								<FormControlLabel
								control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
								label="Livraison via palette"
								style={{ width: 300 }}
								/>
							}
						/>
					</div>

					<div style={{ display: 'flex', paddingBottom: 15 }}>

						<Controller
							style={{ marginLeft: 5 }}
							control={control}
							name={'delivery_picture'}
							defaultValue={false}
							render={props =>
								<FormControlLabel
								control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
								label="Photo"
								/>
							}
						/>

						<Controller
							style={{ marginLeft: 5 }}
							control={control}
							name={'delivery_picture_library'}
							defaultValue={false}
							render={props =>
								<FormControlLabel
								control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
								label="Bibliothèque Photos"
								/>
							}
						/>

						<Controller
							style={{marginLeft : 5}}
							control={control}
							name={'delivery_signature'}
							defaultValue={false}
							render={props =>
								<FormControlLabel
								control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
								label="Signature"
								/>
							}
						/>

						<Controller
							style={{marginLeft : 5}}
							control={control}
							name={'delivery_unsold'}
							defaultValue={false}
							render={props =>
								<FormControlLabel
								control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
								label="Invendus"
								/>
							}
						/>
					</div>

					<div style={{ display: 'flex', paddingBottom: 15 }}>

						<FormControl style={{ width: 200 }}>
							<TextField
								inputRef={register()}
								id={'preparation_hour'}
								name={'preparation_hour'}
								label="Heure limite de préparation"
								type="time"
								defaultValue='19:00'
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									step: 300
								}}
							/>
						</FormControl>

						<FormControl style={{ width: 200, marginLeft: 10 }}>
							<TextField
								inputRef={register()}
								id={'delivery_hour'}
								name={'delivery_hour'}
								label="Heure limite de livraison"
								type="time"
								defaultValue='10:00'
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									step: 300
								}}
							/>
						</FormControl>

					</div>

					<div>
						<Controller
							style={{ marginLeft: 5 }}
							control={control}
							name={'delivery_national'}
							defaultValue={false}
							render={props =>
								<FormControlLabel
								control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
								label="Livraison nationale"
								style={{ width: 250, marginRight: 10 }}
								/>
							}
						/>

						<Controller
							style={{ marginLeft: 5 }}
							control={control}
							name={'delivery_national_direct'}
							defaultValue={false}
							render={props =>
								<FormControlLabel
								disabled={!display_national_direct}
								control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
								label="Livraison plateforme uniquement"
								style={{ width: 350 }}
								/>
							}
						/>
					</div>

				</div>

			</div>


			<div>
				<Typography variant="h6" noWrap><b>Produits</b></Typography>
				{items}
			</div>


			<div style={{ paddingBottom: 125 }}></div>


			<div id='total' style={{display : "flex", flexDirection: 'row', position: 'fixed', left: 0, bottom: 0, width: '100%', backgroundColor: '#ddd', padding : '10px 10px 10px 224px'}}>
				<div style={{ width : 700, height: 105, display : 'flex', justifyContent : 'space-between' }}>
					<div style={{display : "flex", flexDirection: 'column'}}>
						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body2">Nombre de produits</Typography></div>
							<div style={{width: 100, textAlign : 'end'}}><Typography variant="body2">{total_products}</Typography></div>
						</div>
						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body2">Total HT</Typography></div>
							<div style={{width: 100, textAlign : 'end'}}><Typography variant="body2">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.total)}</Typography></div>
						</div>
						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body2">Livraison (franco {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.franco)})</Typography></div>
							<div style={{width: 100, textAlign : 'end'}}><Typography variant="body2">{computed_price.delivery_fee > 0 ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.delivery_fee) : 'offert'}</Typography></div>
						</div>
						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body2">Total TVA</Typography></div>
							<div style={{width: 100, textAlign : 'end'}}><Typography variant="body2">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.tva)}</Typography></div>
						</div>

						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body1"><b>Net à payer</b></Typography></div>
							<div style={{width: 100, textAlign : 'end'}}><Typography variant="body1"><b>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.ttc)}</b></Typography></div>
						</div>
					</div>
					<div style={{display: 'flex', alignItems : 'center'}}>
						<Button type='submit' variant='contained' color='primary' disabled={total_products === 0}>
							{action === 'create' ? 'Commander' : 'Mettre à jour la commande'}
						</Button>
					</div>
				</div>
			</div>
		</div>
		</form>
	);
}

export default React.memo(CreateOrder);
