import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { TextField, Typography, Breadcrumbs, Button, IconButton } from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";

import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from "luxon";

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";
import { TimeContext } from "../store/TimeProvider";

import { GetTotalPrices } from '.././Utils';


function Planogram(data) {

	const { today } = useContext(TimeContext);
	const { user, checkUserRights } = useContext(UsersContext);
	const { GetShop, GetPlanogram, UpdatePlanogram, GetCurrentRateCard, GetCurrentPriceList } = useContext(ShopsContext);
	const { remote_config, products_by_sku, setOpenSnackBar } = useContext(ShakiContext);

	const [products_by_category, setProductsByCategory] = useState({});

	const [planogram, setPlanogram] = useState({});
	const [current, setCurrent] = useState({});
	const [shop, setShop] = useState({});
	const [price_list, setPriceList] = useState({});
	const [rate_card, setRateCard] = useState({});

	const [date_update, setDateUpdate] = useState(null);

	const [plano_conf, setPlanoConf] = useState({});
	const [open_plano_conf, setOpenPlanoConf] = useState(false);
	const [selected_level, setSelectedLevel] = useState(0);
	const ratio = 5;

	var { shop_id, planogram_id } = useParams();

	const { register, handleSubmit, setValue, errors, control, reset } = useForm({
		defaultValues: current
	});


	const { fields, append, remove } = useFieldArray({
		control: control,
		name: 'levels'
	});


	useEffect(() => {

		if (Object.values(remote_config).length === 0) return;

		GetShop(shop_id, (doc) => {
			var data = doc.data();
			setShop(data);
		});

		GetPlanogram(planogram_id, (data) => {

			console.log(data)

			if (data.width === undefined) data.width = 0; //setPlanoWitdh(data.width);
			if (data.depth === undefined) data.depth = 0; //setPlanoDepth(data.depth);
			if (data.height === undefined) data.height = 0; //setPlanoHeight(data.height);

			setCurrent(data);
			reset(data);

			setDateUpdate(DateTime.fromISO(data.date_start));

			var index_brands = {};

			if (data.levels !== undefined) {

				for (var i in data.levels) {

					var level = data.levels[i];
					if (level.conf !== undefined) {
						for (var j in level.conf.areas) {

							if (index_brands[level.conf.areas[j].brand] === undefined) index_brands[level.conf.areas[j].brand] = 0;

							level.conf.areas[j].index = index_brands[level.conf.areas[j].brand];
							index_brands[level.conf.areas[j].brand] += 1;
						}
						plano_conf[i] = level.conf;
					}
				}

				setPlanoConf({...plano_conf});
			}
		});
	
	}, [remote_config]);

	useEffect(() => {

		if (Object.values(shop).length === 0) return;
		if (Object.values(current).length === 0) return;

		setPriceList(GetCurrentPriceList(shop.pricelist_group_id, today));
		setRateCard(GetCurrentRateCard(today));

	}, [current, shop]);


	const onSubmit = (raw_data) => {

		var data = JSON.parse(JSON.stringify(raw_data));
		data.date_start = parseInt(date_update.toFormat('yyyyLLdd'));

		if (data.width !== '') data.width = parseFloat(data.width);
		if (data.depth !== '') data.depth = parseFloat(data.depth);
		if (data.height !== '') data.height = parseFloat(data.height);

		// push conf
		for (var i in data.levels) {
			data.levels[i].conf = plano_conf[i];
		}

		data.stock = current.stock === undefined ? {} : current.stock;
		//console.log(data);

		return UpdatePlanogram(current.id, data, user, () => {
			setOpenSnackBar('Sauvegardé')
		});
	};


	const addPlanoArea = () => {
		if (plano_conf[selected_level] === undefined) plano_conf[selected_level] = { areas: [] }
		plano_conf[selected_level].areas.push({ brand: 'shaki_shaki', width: 100 });
		return setPlanoConf({...plano_conf})
	};


	const removePlanoArea = (index) => {
		plano_conf[selected_level].areas.splice(index, 1);
		return setPlanoConf({...plano_conf})
	};


	const savePlanoConfBrand = (index, brand) => {
		plano_conf[selected_level].areas[index].brand = brand;
		return setPlanoConf({...plano_conf})
	};


	const savePlanoConfWidth = (index, width) => {
		plano_conf[selected_level].areas[index].width = parseInt(width);
		return setPlanoConf({...plano_conf})
	};


	const handleClose = () => {
		setOpenPlanoConf(false)
	}


	const get = (brand, area) => {

		if (current.products === undefined) return [];
		if (current.products[brand] === undefined) return [];
		if (current.products[brand][area] === undefined) return [];

		return current.products[brand][area].products;
	};


	const getProductWidth = (sku) => {
		return remote_config.packaging_type[products_by_sku[sku].packaging.type].width / 10;
	};


	const getProductHeight = (sku) => {
		return remote_config.packaging_type[products_by_sku[sku].packaging.type].height / 10;
	};


	const addStock = (sku) => {

		if (current.stock === undefined) current.stock = {};
		if (current.stock[sku] === undefined) current.stock[sku] = 0;
		current.stock[sku] = Math.max(0, current.stock[sku] + 1);

		return setCurrent({...current});
	};


	const removeStock = (sku) => {

		if (current.stock === undefined) current.stock = {};
		if (current.stock[sku] === undefined) current.stock[sku] = 0;
		current.stock[sku] = Math.max(0, current.stock[sku] - 1);

		return setCurrent({...current});
	};


	const getStock = (sku) => {
		return (current.stock === undefined || current.stock[sku] === undefined) ? 0 : current.stock[sku];
	};


	return (
		<div style={{ padding: 20 }}>

			<Dialog onClose={handleClose} open={open_plano_conf} fullWidth={true} maxWidth='xs' scroll='paper'>
				<DialogContent dividers={true}>
					{plano_conf[selected_level] !== undefined &&

						<div style={{ display: 'flex', flexDirection: 'column' }}>
						{plano_conf[selected_level].areas.map((area, index) => {
							return(
							<div key={'dialog_area_' + area.brand + index} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
								<Select
									style={{ width: 200 }}
									native
									defaultValue={area.brand}
									onChange={(e) => savePlanoConfBrand(index, e.target.value)}
								>
									<option value='other'>Autre</option>
									{Object.entries(remote_config.brands).map((b, i) => (
										<option key={b[0]} value={b[0]}>{b[1].name}</option>
									))}
								</Select>
								<TextField
									name='width'
									label='Largeur'
									style={{ width: 70, marginLeft: 10 }}
									defaultValue={area.width}
									onChange={(e) => savePlanoConfWidth(index, e.target.value)}
									InputProps={{
										endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
									}}
								/>
								<IconButton onClick={() => removePlanoArea(index)}>
									<DeleteIcon fontSize="inherit"/>
								</IconButton>
							</div>
							)}
						)}
						</div>
					}

					<Button onClick={(e) => {addPlanoArea(); return true;}} variant='contained' color='default' vsize='small'>
						Ajouter une marque
					</Button>

				</DialogContent>
			</Dialog>

			<Breadcrumbs aria-label='breadcrumb' style={{ marginBottom: 20 }}>
				<Link to='/'>Accueil</Link>
				<Link to={"/shops/" + shop_id}>{shop.name}</Link>
				<Link to={"/shops/" + shop_id + "/planograms"}>Planogrammes</Link>
				<Typography>{current.name}</Typography>
			</Breadcrumbs>

			<div>
				<form onSubmit={handleSubmit(onSubmit)}>

					<div style={{ marginBottom: 10 }}>

						<div style={{ marginBottom: 20 }}>

							<TextField
								required
								inputRef={register({
									required: true
								})}
								InputLabelProps={{
									shrink: true,
								}}
								name='name'
								label='Label'
								style={{ width: 200 }}
								error={errors.name ? true : false}
							/>

							<MuiPickersUtilsProvider utils={LuxonUtils}>
								<KeyboardDatePicker
									name='date_start'
									disableToolbar
									format='d MMMM yyyy'
									margin='normal'
									label="Date d'application"
									KeyboardButtonProps={{
										'aria-label': 'change date'
									}}
									value={date_update}
									onChange={(date) => {
										return setDateUpdate(date)
									}}
									style={{ width: 200, marginLeft: 10, marginTop: 0 }}
								/>
							</MuiPickersUtilsProvider>
						</div>

						<div style={{ marginBottom: 40 }}>

							<TextField
								inputRef={register()}
								name={'width'}
								label='Largeur descente (cm)'
								type='number'
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									inputProps: {
										min: 1,
										max: 500,
										step: 1,
									}
								}}
								onChange={(e) => {setCurrent({
									...current, width: parseFloat(e.target.value)
									})}
								}
								style={{ width: 200, marginRight: 10 }}
							/>

							<TextField
								inputRef={register()}
								name={'depth'}
								label='Profondeur descente (cm)'
								type='number'
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									inputProps: {
										min: 1,
										max: 100,
										step: 1,
									}
								}}
								onChange={(e) => {setCurrent({
									...current, depth: parseFloat(e.target.value)
									})}
								}
								style={{ width: 200, marginRight: 10 }}
							/>

							<TextField
								inputRef={register()}
								name={'height'}
								label='Hauteur étagère (cm)'
								type='number'
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									inputProps: {
										min: 1,
										max: 100,
										step: 1,
									}
								}}
								onChange={(e) => {setCurrent({
									...current, height: parseFloat(e.target.value)
									})}
								}
								style={{ width: 200 }}
							/>
						</div>

						<div style={{ width: 700, display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
							<>
							{fields.map((field, index) => (

								<div key={field.id} style={{ display: 'flex', marginBottom: 2 }}>

									<div style={{ display: 'flex' }}>

										<TextField
											hidden
											inputRef={register()}
											name={`levels[${index}].index`}
											defaultValue={index}
										/>

										{plano_conf[index] !== undefined ?

											<div style={{ display: 'flex', flexDirection: 'row', borderWidth: 1, borderStyle: 'solid', height: current.height * ratio, width: current.width * ratio }}>

												{plano_conf[index].areas.map((area, index2) => (
													<div key={'area_' + index + '_' + index2} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: area.width * ratio, backgroundColor: remote_config.brands[area.brand] !== undefined ? remote_config.brands[area.brand].secondary_color : 'white' }}>

														<div style={{ textAlign: 'center', fontSize: 8 }}>
															{area.brand} - {area.width} cm
														</div>

														<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end' }}>

															{get(area.brand, area.index).map((product, index3) => (
																<div key={area.brand + '_' + area.index + '_' + index3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
																	<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , marginBottom: 5 }}>
																		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

																			<IconButton style={{ width: 14, height: 14 }} onClick={() => {removeStock(product.sku)}} size="small">
																				<RemoveIcon fontSize="inherit" />
																			</IconButton>

																			<div style={{ fontSize: 13, fontWeight: '600', width: 14, textAlign: 'center' }}>{getStock(product.sku)}</div>

																			<IconButton style={{ width: 14, height: 14 }} onClick={() => {addStock(product.sku)}} size="small">
																				<AddIcon fontSize="inherit" />
																			</IconButton>

																		</div>
																		<div style={{ fontSize: 6 }}>{product.sku}</div>
																	</div>
																	<img key={product.sku} title={product.sku + ' - ' + products_by_sku[product.sku].name} src={"https://firebasestorage.googleapis.com/v0/b/shakishaki-243509.appspot.com/o/packaging%2F" + encodeURI(products_by_sku[product.sku].packaging.type) + ".png?alt=media"} width={getProductWidth(product.sku) * ratio} height={getProductHeight(product.sku) * ratio}/>
																</div>
															))}

														</div>
													</div>
												))}

											</div>
											:
											<div style={{ display: 'flex', flexDirection: 'row', borderWidth: 1, borderStyle: 'solid', height: current.height * ratio, width: current.width * ratio }}>
											</div>
										}

									</div>

									<IconButton onClick={() => {
										setOpenPlanoConf(true)
										setSelectedLevel(index);
									}}>
										<EditIcon fontSize="inherit"/>
									</IconButton>

									<IconButton onClick={() => remove(index)}>
										<DeleteIcon fontSize="inherit"/>
									</IconButton>

								</div>
							))}
							<Button style={{ marginTop: 10, marginBottom: 70 }} variant='contained' color='default' onClick={() => append({})}>
								Ajouter une étagère
							</Button>
							</>
						</div>



					</div>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'fixed', bottom: 0, left: 200, width: '100%', backgroundColor: 'white', padding: 24, borderTop: 'solid rgba(0, 0, 0, 0.12) 1px' }}>
						<Button disabled={!checkUserRights('planograms_write')} variant="contained" color="primary" type='submit'>
							Enregistrer
						</Button>
						<div style={{ marginLeft: 20, fontSize: 20, }}>
							{GetTotalPrices(rate_card, price_list, current.stock !== undefined ? current.stock : {})}
						</div>
					</div>

				</form>
			</div>
		</div>
	);
}

export default React.memo(Planogram);

