import React from 'react';
import firebase from "firebase/compat/app";

import { useState, useEffect, useContext, useCallback, useRef, forwardRef } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, IconButton, Button, TextField, Avatar, Collapse, Select, InputLabel, FormControl } from '@material-ui/core';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CreateIcon from '@material-ui/icons/Create';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { Link } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import LuxonUtils from '@date-io/luxon';
import { DateTime } from "luxon";

import { ToursContext } from "../store/ToursProvider";
import { TimeContext } from "../store/TimeProvider";
import { UsersContext } from '../store/UsersProvider';

import { DndContext, closestCenter, closestCorners, KeyboardSensor, PointerSensor, useSensor, useSensors, DragOverlay, useDroppable } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable, AnimateLayoutChanges, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { GetLocalStorage, SetLocalStorage } from '.././Utils';

import { button, text } from '../Style.js';
import { GetImagePath } from '.././Utils';


function Tours() {

	const { users_hash } = useContext(UsersContext);
	const { GetTours } = useContext(ToursContext);
	const { day_prod } = useContext(TimeContext);

	const [original_tours, setOriginalTours] = useState({});
	const [tours, setTours] = useState({});
	const [routes, setRoutes] = useState({});
	const [coordinnates, setCoordinnates] = useState({});
	const [selected_date, setSelectedDate] = useState(GetLocalStorage('tours_selected_date') !== null ? DateTime.fromISO(GetLocalStorage('tours_selected_date')) : DateTime.fromISO(day_prod));
	const [activeId, setActiveId] = useState(null);

	const [city_filter, setCityFilter] = useState('Paris');


	const title = 'Tournées';
	const classes = useStyles();
	const obsolete = true;

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const defaultAnnouncements = {
		onDragStart(id) {
			console.log(`Picked up draggable item ${id}.`);
		},
		onDragOver(id, overId) {
			if (overId) {
				console.log(
					`Draggable item ${id} was moved over droppable area ${overId}.`
				);
				return;
			}

			console.log(`Draggable item ${id} is no longer over a droppable area.`);
		},
		onDragEnd(id, overId) {
			if (overId) {
				console.log(
					`Draggable item ${id} was dropped over droppable area ${overId}`
				);
				return;
			}

			console.log(`Draggable item ${id} was dropped.`);
		},
		onDragCancel(id) {
			console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
		}
	};


	useEffect(() => {

		console.log('>>', selected_date);
		if (Object.values(routes).length === 0) return;

		GetTours(parseInt(selected_date.toFormat('yyyyLLdd')), (docs) => {

			var hash = {};
			var arr = [];
			docs.forEach(doc => {
				var data = doc.data();
				hash[data.tour] = data;
				arr.push(data);
			});

			console.log(hash);
			setTours(hash);
			setOriginalTours(hash);
		});

	}, [selected_date, routes]);


	useEffect(() => {


		firebase.firestore().collection('routes').where('date', '==', parseInt(selected_date.toFormat('yyyyLLdd'))).onSnapshot((docs) => {

			var hash_routes = {};
			docs.forEach((doc) => {
				var data = doc.data();
				hash_routes[doc.id] = data;
			});

			setRoutes(hash_routes);
		});

	}, [selected_date]);


	const handleDateChange = (date) => {
		SetLocalStorage('tours_selected_date', date.toFormat('yyyyLLdd'));
		setTours({});
		setSelectedDate(date);
		return true;
	};


	const handleDragStart = (event) => {
		const {active} = event;
		setActiveId(active.id);
	};


	const findContainer = (id) => {
		return Object.keys(tours).find((key) => tours[key].list.includes(id));
	};


	const handleDragOver = (event) => {

		const { active, over } = event;
		if (!over) return;

		const activeContainer = findContainer(active.id);
		const overContainer = findContainer(over.id);

		if (activeContainer !== overContainer) {

			console.log('drag over other container')
/*
			setTours((prev) => {

				const activeItems = prev[activeContainer].list.filter((item) => item !== active.id);
				const overItems = [...prev[overContainer].list, active.id];

				return {
					...prev,
					[activeContainer]: { ...prev[activeContainer], list: activeItems },
					[overContainer]: { ...prev[overContainer], list: overItems },
				};
			});
*/
			if (activeContainer !== overContainer) {
        // Mettez à jour uniquement les listes affectées
        setTours((prev) => {
            const updatedTours = { ...prev };

            updatedTours[activeContainer].list = prev[activeContainer].list.filter(
                (item) => item !== active.id
            );
            updatedTours[overContainer].list = [...prev[overContainer].list, active.id];

            return updatedTours;
        });
    }

		}
	};


	const handleDragEnd = (event) => {

    	const {active, over} = event;

		var tour_id = over.data.current.sortable.containerId

		const activeContainer = findContainer(active.id);
		const overContainer = findContainer(over.id);

    	//console.log(active.id, activeContainer, tours[activeContainer].list.indexOf(active.id));
    	//console.log(over.id, overContainer, tours[overContainer].list.indexOf(over.id));

		if (active.id !== over.id) {

			console.log('release drag');

			var tour = tours[tour_id];

			const oldIndex = tours[tour_id].list.indexOf(active.id);
			const newIndex = tours[tour_id].list.indexOf(over.id);


			tours[tour_id].list = arrayMove(tours[tour_id].list, oldIndex, newIndex);
			setTours({...tours});
		};

		save(tours, original_tours);

		return;
	};


	const save = (tours, original_tours) => {

		console.log('save', activeId);

		var old_tour;
		for (var tour in original_tours) {

			if (original_tours[tour].list.indexOf(activeId) !== -1) {
				old_tour = tour;
			};
		};

		var new_tour;
		var new_pos;
		for (var tour in tours) {

			if (tours[tour].list.indexOf(activeId) !== -1) {
				new_tour = tour;
				new_pos = tours[tour].list.indexOf(activeId)
				console.log(tours[tour].list)
			};
		};


		console.log('old_tour', old_tour);
		console.log('new_tour', new_tour);
		console.log('new_pos', new_pos);

		return setActiveId(null);
	};


	var colors = ['#50808e', '#654f6f', '#cce2a3', '#0a122a', '#9a031e', '#7adfbb', '#321325', '#cb793a', '#fcdc4d', '#804e49', '#4e6e58']


	const Tour = React.memo((data) => {

		const { id , tour } = data;

		const { setNodeRef } = useDroppable({
			id
		});

		return (
			<div key={tour.tour}>

				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, fontSize: 18 }}>
					{tour.worker !== undefined &&
						<Avatar
							src={GetImagePath(users_hash[tour.worker].images, 'main')}
							style={{ width: 40, height: 40, marginRight: 10 }}
						/>
					}
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<b>{tour.tour_name}</b>
						<div style={{ fontSize: 12 }}>{tour.operator}</div>
					</div>
				</div>

				<div style={{ marginBottom: 30 }}>

					<div style={{ height: 1, backgroundColor: '#000' }} />

					<SortableContext id={tour.tour} items={tour.list.slice(1)} strategy={verticalListSortingStrategy}>
						{tour.list.map((route_id, index2) => (
							<Route key={route_id} id={route_id} handle={true} index={index2} />
						))}
					</SortableContext>
				</div>
			</div>
		)

	});


	const Route = React.memo((data) => {

		const {
			attributes,
			listeners,
			setNodeRef,
			transform,
			transition,
			isDragging
		} = useSortable({ id: data.id });

		var route = routes[data.id];


		var background_color = route.type === 'pickup' ? '#DBE1CB' : '#F5F5F5';
		var text_decoration = 'none';

		if (route.state === 1) {
			var background_color = '#ff8800';
		};
		if (route.state === 2) {
			var background_color = route.type === 'pickup' ? '#B2FFB0' : '#D7FFD6';
		};

		const style = {
			display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
			fontSize: 14,
			//borderBottomWidth: 1,
			//borderColor: '#CCC',
			//borderBottomStyle: 'solid',
			transform: CSS.Transform.toString(transform),
			transition,
			padding: 10, alignItems: 'center',
			backgroundColor: background_color,
			opacity: isDragging ? 0.5 : 1
		};

		var past = route.completed_at !== undefined;

		return (

			<div ref={setNodeRef} style={style} >

				<div style={{ marginRight: 10, width: 350, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

					{/*past === false && */route.type !== 'pickup' &&
					 <IconButton {...listeners} {...attributes} size='small'>
						<DragIndicatorIcon style={{ width: 15, height: 15 }} />
					</IconButton>
					}

					{past &&
					<div style={{ backgroundColor: 'white', borderRadius: 10, padding: 5, fontSize: 12, marginRight: 10 }}>
						{DateTime.fromMillis(route.completed_at.time * 1000).toFormat('HH:mm')}
					</div>
					}

					<div style={{...text.ellipsis, ...{ width: 300 }}}>
						<Link style={{ color: 'black' }} to={'/shops/' + route.address.shop_id}>{route.address.name}</Link>
					</div>
				</div>

				<div style={{...text.ellipsis, ...{ width: 300 }}}>
					{route.address.formatted_address}
				</div>

				<div style={{ width: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
					<CreateIcon style={{ width: 20, height: 20, color: 'black', opacity: route.signature ? 1 : 0.2, marginRight: 10 }} />
					<CameraAltIcon style={{ width: 20, height: 20, color: 'black', opacity: route.picture ? 1 : 0.2 }} />
				</div>

				{/*
				{(route.address.name.search('STF') !== -1 && route.type !== 'pickup' && route.orders.length > 1) &&
					<SortableContext id={route.id} items={route.orders} strategy={verticalListSortingStrategy}>
						{route.orders.map((order_id, index) => (
							<Order key={order_id} id={order_id} handle={true} index={index} />
						))}
					</SortableContext>
				}
				*/}

			</div>
		);
	});


	const Order = (data) => {

		const {
			attributes,
			listeners,
			setNodeRef,
			transform,
			transition,
			isDragging
		} = useSortable({ id: data.id });


		const style = {
			fontSize: 14,
			borderBottomWidth: 1,
			borderColor: '#CCC',
			borderBottomStyle: 'solid',
			transform: CSS.Transform.toString(transform),
			transition,
			display: 'flex', flexDirection: 'row', padding: 10, alignItems: 'center',
			backgroundColor: '#E5E5E5',
			opacity: isDragging ? 0.5 : 1
		};

		return (
			<div ref={setNodeRef} style={style} >
				<div style={{ width: '40%'}}>
					 <IconButton style={{ marginLeft: 10 }} {...listeners} {...attributes} size="small">
						<DragIndicatorIcon style={{ width: 15, height: 15 }} />
					</IconButton>
					{data.id}
				</div>
			</div>
		);
	};



	const RouteOverlay = forwardRef(({ id }, ref) => {

		const style = {
			display: 'flex',
			flexDirection: 'row',
			padding: 10,
			width: '100%',
			zIndex: 10000,
			backgroundColor: '#F5F5F5',
			boxShadow: "5px 5px 10px #9E9E9E",
		};

		var route = routes[id];

		return (
			<div ref={ref} style={{...text.ellipsis, ...style}}>
				{route !== undefined ? route.address.name : id}
			</div>
		);
	});


	return (
    	<div>

			<div style={{ padding: 20 }} >
				<Breadcrumbs aria-label="breadcrumb">
					<Link to='/'>Accueil</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>
				<h2>{title}</h2>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div />
					<div>
						<FormControl style={{ width: 150, marginRight: 10 }} >
							<InputLabel htmlFor={'city_filter'}>Filtrer par ville</InputLabel>
							<Select
								native
								value={city_filter}
								onChange={(e) => {setCityFilter(e.target.value)}}
								inputProps={{
									name: 'city',
									id: 'city_filter'
								}}
							>
								<option value=''>--</option>
								<option>Aix-en-Provence</option>
								<option>Bordeaux</option>
								<option>Lille</option>
								<option>Lyon</option>
								<option>Marseille</option>
								<option>Nice / Cannes</option>
								<option>Paris</option>
							</Select>
						</FormControl>

						<MuiPickersUtilsProvider utils={LuxonUtils}>
							<KeyboardDatePicker
								id='tours_date'
								disableToolbar
								variant='inline'
								format='dd/MM/yyyy'
								label='Date'
								value={selected_date}
								onChange={handleDateChange}
								KeyboardButtonProps={{
									'aria-label': 'change date'
								}}
							/>
						</MuiPickersUtilsProvider>
					</div>
				</div>
			</div>

			<div>
				<DndContext
        			announcements={defaultAnnouncements}
					sensors={sensors}
					collisionDetection={closestCorners}
					onDragStart={handleDragStart}
					onDragOver={handleDragOver}
					onDragEnd={handleDragEnd}
				>

					{Object.values(tours).filter((row) => row.tour_name.search(city_filter) !== -1).sort((a, b) => a.tour_name > b.tour_name ? 1 : -1).map((tour, index) => (
						<Tour key={tour.tour} id={tour.tour} tour={tour} index={index} />
					))}

					<DragOverlay>
						{activeId ? <RouteOverlay id={activeId} /> : null}
					</DragOverlay>

				</DndContext>

			</div>

		</div>
	);
};

export default React.memo(Tours);


const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	tr: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#F5F5F5',
		}
	},
	tr_pickup: {
		'&:nth-of-type(2n+1)': {
			backgroundColor: '#EDF0E5',
		},
		'&:nth-of-type(2n)': {
			backgroundColor: '#DBE1CB',
		}
	},
});



