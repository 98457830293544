import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { IconButton, Select, Typography, Breadcrumbs, Button, Avatar, Checkbox} from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";

import { useForm, Controller, useFieldArray } from "react-hook-form";

import Resizer from 'react-image-file-resizer';
import DeleteIcon from '@material-ui/icons/Delete';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';


import { ShakiContext } from "../store/ShakiProvider";


function LunaProduct() {

	const { products_by_sku, GetLunaProducts, CreateLunaProduct, UpdateLunaProduct, GetLunaProduct, setOpenSnackBar, getRemoteConf } = useContext(ShakiContext);

	const classes = useStyles();
	var history = useHistory();

	var { product_id } = useParams();
	var action = product_id === 'new' ? 'create' : 'update';

	const [current_product, setCurrentProduct] = useState({});

	const [luna_products, setLunaProducts] = useState({});
	const [luna_products_list, setLunaProductsList] = useState([]);
	const [products_list, setProductsList] = useState([]);
	const [products_options_list, setProductsOptionsList] = useState([]);

	const [luna_categories, setLunaGategories] = useState({});
	const [luna_brands, setLunaBrands] = useState({});
	const [luna_customization_categories, setLunaCustomizationCategories] = useState({});

	const [picture_1, setPicture1] = useState(null);
	const [picture_2, setPicture2] = useState(null);


	useEffect(() => {
		setLunaGategories(getRemoteConf('luna_categories'));
		setLunaBrands(getRemoteConf('luna_brands'));
		setLunaCustomizationCategories(getRemoteConf('luna_customization_categories'));
	}, []);



	var title = (current_product.name !== undefined) ? current_product.name : 'Nouveau';

	const { register, handleSubmit, errors, control, reset } = useForm({
		//defaultValues: current_product
	});

	const { fields: fields_brands, append: append_brands, remove: remove_brands } = useFieldArray({
		control,
		name: 'brands'
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'composition_base'
	});

	const { fields: fields_option, append: append_option, remove: remove_option } = useFieldArray({
		control,
		name: 'composition_option'
	});

	const { fields: fields_size, append: append_size, remove: remove_size } = useFieldArray({
		control,
		name: 'size'
	});

	const { fields: fields_cross_sell, append: append_cross_sell, remove: remove_cross_sell } = useFieldArray({
		control,
		name: 'cross_sell'
	});


	useEffect(() => {

		if (product_id === 'new') return;
		if (Object.values(products_by_sku).length === 0) return;

		GetLunaProduct(product_id, (doc) => {

			var data = doc.data();

			if (data.cross_sell !== undefined) {
				for (var i in data.cross_sell) {
					data.cross_sell[i].product_id = data.cross_sell[i].id;
					delete data.cross_sell[i].id;
				}
			}

			reset(data);

			if (data.picture_1 !== undefined) setPicture1(data.picture_1);
			if (data.picture_2 !== undefined) setPicture2(data.picture_2);

			setCurrentProduct(data);

		});

	}, [product_id, products_by_sku]);


	useEffect(() => {

		const unsubscribe = GetLunaProducts((docs) => {

			if (docs === null) return;

			const hash = {};
			const list = []
			docs.forEach(doc => {
				list.push(doc.data());
				hash[doc.id] = doc.data();
			});


			setLunaProductsList(list);
			setLunaProducts(hash);
		});

	}, []);


	useEffect(() => {

		var products_list = [];
		for (var sku in products_by_sku) {
			if (products_by_sku[sku].sub_recipe) continue;
			products_list.push(products_by_sku[sku]);
		}
		setProductsList(products_list);

		var products_options_list = [];
		for (var sku in products_by_sku) {
			if (products_by_sku[sku].sku.search('LUN') === -1) continue;
			products_options_list.push(products_by_sku[sku]);
		}
		setProductsOptionsList(products_options_list);

	}, []);



	const onSubmit = (raw_data) => {

		var data = JSON.parse(JSON.stringify(raw_data));

		if (picture_1 !== null) data.picture_1 = picture_1;
		if (picture_2 !== null) data.picture_2 = picture_2;

		var allergens = [];
		var new_composition_base = [];
		var total_weight = 0;

		var nutrition = {
			kcal: 0,
			kj: 0,
			proteins: 0,
			fat: 0,
			saturated_fat: 0,
			carbs: 0,
			sugar: 0,
			salt: 0
		};

		for (let i in data.composition_base) {

			var quantity = data.composition_base[i].quantity !== '' ? parseFloat(data.composition_base[i].quantity) : '';

			new_composition_base.push({
				quantity: quantity,
				sku: data.composition_base[i].sku.sku
			});

			var product = data.composition_base[i].sku;
			var quantity = data.composition_base[i].quantity !== '' ? parseFloat(data.composition_base[i].quantity) : product.weight;
			total_weight += quantity;
		}

		for (let i in data.composition_base) {

			var product = data.composition_base[i].sku;
			var quantity = data.composition_base[i].quantity !== '' ? parseFloat(data.composition_base[i].quantity) : product.weight;
			var ratio = (quantity / total_weight);

			nutrition.kcal += Math.floor(product.nutrition.kcal * ratio);
			nutrition.kj += Math.floor(product.nutrition.kj * ratio);
			nutrition.proteins += Math.floor(product.nutrition.proteins * ratio);
			nutrition.fat += Math.floor(product.nutrition.fat * ratio);
			nutrition.saturated_fat += Math.floor(product.nutrition.saturated_fat * ratio);
			nutrition.carbs += Math.floor(product.nutrition.carbs * ratio);
			nutrition.sugar += Math.floor(product.nutrition.sugar * ratio);
			nutrition.salt += Math.floor(product.nutrition.salt * ratio);

			for (let j in product.allergens) {
				if (!allergens.includes(product.allergens[j])) {
					allergens.push(product.allergens[j]);
				}
			}
		}


		var new_composition_option = [];
		for (let i in data.composition_option) {
			new_composition_option.push({
				additional_cost: data.composition_option[i].additional_cost !== '' ? parseFloat(data.composition_option[i].additional_cost) : 0,
				sku: data.composition_option[i].sku.sku,
				category: data.composition_option[i].category,
			});
		}

		var new_cross_sell = [];
		for (let i in data.cross_sell) {
			new_cross_sell.push({
				additional_cost: data.cross_sell[i].additional_cost !== '' ? parseFloat(data.cross_sell[i].additional_cost) : 0,
				id: data.cross_sell[i].product_id.id,
			});
		}

		var new_size = [];
		for (let i in data.size) {
			new_size.push({
				additional_cost: data.size[i].additional_cost !== '' ? parseFloat(data.size[i].additional_cost) : 0,
				label: data.size[i].label,
			});
		}

		data.size = new_size;
		data.composition_base = new_composition_base;
		data.composition_option = new_composition_option;
		data.cross_sell = new_cross_sell;
		data.nutrition = nutrition;
		data.allergens = allergens;
		data.weight = total_weight;
		data.price = parseFloat(data.price);
		data.heat_up_time = data.heat_up_time !== '' ? parseInt(data.heat_up_time) : 0;

		for (let i in data.constraints) {

			if (isNaN(parseInt(data.constraints[i].number))) {
				delete data.constraints[i].number;
				continue;
			}
			data.constraints[i].number = parseInt(data.constraints[i].number);

			if (Object.values(data.constraints[i]).length === 0) delete data.constraints[i];
		}

		if (action === 'create') {

			CreateLunaProduct(data, (id) => {
				setOpenSnackBar('Sauvegardé')
				history.push('/luna_products/' + id);
			});

		} else {

			UpdateLunaProduct(product_id, data, (id) => {
				setOpenSnackBar('Sauvegardé')
			});
		}
	};


	const fileChangedHandler = (event) => {

		if (event.target.files[0]) {
			Resizer.imageFileResizer(event.target.files[0], 1200, 1200, 'JPEG', 95, 0, uri => {
				if (event.target.id === 'picture_1') setPicture1(uri)
				if (event.target.id === 'picture_2') setPicture2(uri)
			}, 'base64');
		}
	}


	return (
		<div style={{ padding: 20 }}>

			<Breadcrumbs aria-label="breadcrumb">
				<Link to='/'>Dashboard</Link>
				<Link to='/luna_products'>Produits Luna</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{ paddingBottom : 40 }}>{title}</h2>


			<div style={{ width: 700 }}>
				<form onSubmit={handleSubmit(onSubmit)}>

					<div style={{ paddingBottom: 40 }}>

						<div style={{ display: 'flex', paddingBottom: 10 }}>

							<TextField
								required
								inputRef={register({
									required: true
								})}
								InputLabelProps={{ shrink: true }}
								name='name'
								label='Nom'
								style={{ width: '100%' }}
								error={errors.name ? true : false}
							/>
						</div>

						<div style={{display: 'flex', marginBottom: 15}}>
							<TextField
								InputLabelProps={{ shrink: true }}
								inputRef={register()}
								name='locales.en.name'
								label='Nom 🇬🇧'
								style={{ marginLeft: 20, width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', paddingBottom: 15 }}>
							<TextField
								inputRef={register()}
								InputLabelProps={{ shrink: true }}
								name='description'
								label='Description'
								multiline
								style={{width: '100%'}}
							/>
						</div>

						<div style={{display: 'flex', marginBottom: 15}}>
							<TextField
								InputLabelProps={{ shrink: true }}
								inputRef={register()}
								name='locales.en.description'
								label='Description 🇬🇧'
								multiline
								style={{ marginLeft: 20, width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', paddingBottom: 10 }}>
							<FormControl className={classes.formControl} style={{ width: 200 }}>
								<InputLabel htmlFor="brand">Marque</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'brand',
										id: 'brand',
									}}
									InputLabelProps={{ shrink: true }}
								>
									<option value='none'>---</option>
									{Object.entries(luna_brands).map((field, index) => (
										<option value={field[0]}>{field[1]}</option>
									))}
								</Select>
							</FormControl>
						</div>

						<div style={{ paddingBottom: 20 }}>

							{fields_brands.map((field, index) => (
							<div key={field.id} style={{ display: 'flex', paddingBottom: 15 }}>

								<Select
									style={{ width: 200 }}
									inputRef={register()}
									native
									inputProps={{
										name: `brands[${index}].brand`
									}}
									defaultValue={field.brand}
								>
									<option value=''>---</option>
									{Object.entries(luna_brands).map((b, i) => (
										<option value={b[0]}>{b[1]}</option>
									))}
								</Select>

								<IconButton onClick={() => remove_brands(index)}>
									<DeleteIcon fontSize="inherit"/>
								</IconButton>

							</div>
							))}

							<Button type="button" variant="contained" size="small" color="default" onClick={() => append_brands({brand: ''})} >
								Ajouter une marque
							</Button>

						</div>

						<div style={{ display: 'flex', paddingBottom: 10 }}>
							<FormControl className={classes.formControl} style={{ width: 200 }}>
								<InputLabel InputLabelProps={{ shrink: true }} htmlFor="category">Categorie</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'category',
										id: 'category',
									}}
									InputLabelProps={{ shrink: true }}
								>
									<option value='none'>---</option>
									{Object.entries(luna_categories).map((field, index) => (
										<option value={field[0]}>{field[1]}</option>
									))}

								</Select>

							</FormControl>
						</div>

						<div style={{ display: 'flex', paddingBottom: 10 }}>
							<TextField
								inputRef={register()}
								name='price'
								label='Prix de vente'
								InputProps={{
									endAdornment:<InputAdornment position="end">€</InputAdornment>,
								}}
								InputLabelProps={{ shrink: true }}
								style={{ width: 100 }}
							/>
						</div>

						<div style={{ display: 'flex', paddingBottom: 10 }}>
							<FormControl className={classes.formControl} style={{ width: 100 }}>
								<InputLabel htmlFor="brand">TVA</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'tva',
										id: 'tva',
									}}
									InputLabelProps={{ shrink: true }}
								>
									<option value='5_5'>5.5%</option>
									<option value='10'>10%</option>
									<option value='20'>20%</option>
								</Select>
							</FormControl>
						</div>

						<div style={{ display: 'flex', paddingBottom: 10 }}>
							<Controller
								name={'enabled'}
								control={control}
								defaultValue={false}
								render={props =>
									<FormControlLabel
									control={<Checkbox onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
									label='Actif'
									style={{ width: 170 }}
									/>
								}
							/>
						</div>


					</div>

					<div style={{ paddingBottom: 40 }}>

						<Typography variant='h6' noWrap style={{paddingBottom: 20}}><b>Composition</b></Typography>

						<div style={{ paddingBottom: 20 }}>

							{fields.map((field, index) => (
							<div key={field.id} style={{ display: 'flex', paddingBottom: 15 }}>

								<Controller
	      						render={props => (
									<Autocomplete
										{...props}

										options={products_list}
										getOptionLabel={(option) => (option.sku + ' - ' + option.name)}
										getOptionSelected={(option, value) => option.sku === value.sku}

										onChange={(_, data) => props.onChange(data)}

										style={{ width: '75%', paddingRight: 15 }}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label="Base"
												placeholder="Base"
											/>
										)}
									/>
								)}
								defaultValue={products_by_sku[field.sku]}
								name={`composition_base[${index}].sku`}
								control={control}
								/>

								<TextField
									inputRef={register({})}
									name={`composition_base[${index}].quantity`}
									defaultValue={field.quantity}
									label='Quantité'
									InputProps={{
										endAdornment:<InputAdornment position='end'>g</InputAdornment>,
									}}
									style={{ width: '20%' }}
								/>

								<IconButton onClick={() => remove(index)}>
									<DeleteIcon fontSize="inherit"/>
								</IconButton>

							</div>
							))}

							<Button type="button" variant="contained" size="small" color="default" onClick={() => append({sku: '', additional_cost: '0', category:''})} >
								Ajouter une base
							</Button>

						</div>

						<div style={{ paddingBottom: 20 }}>

							{fields_option.map((field, index) => (
							<div key={field.id} style={{ display: 'flex', paddingBottom: 15 }}>

								<Controller
	      						render={props => (
									<Autocomplete
										{...props}

										options={products_options_list}
										getOptionLabel={(option) => (option.sku + ' - ' + option.name)}
										getOptionSelected={(option, value) => option.sku === value.sku}

										onChange={(_, data) => props.onChange(data)}

										style={{ width: '55%', paddingRight: 15 }}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label='Option'
												placeholder="Option"
											/>
										)}
									/>
								)}
								defaultValue={products_by_sku[field.sku]}
								name={`composition_option[${index}].sku`}
								control={control}
								/>

								<FormControl className={classes.formControl} style={{ width: '20%', paddingRight: 15 }}>
									<InputLabel htmlFor="category">Categorie</InputLabel>
									<Select
										inputRef={register()}
										native
										defaultValue={field.category}
										inputProps={{
											name:`composition_option[${index}].category`,
											id:`composition_option[${index}].category`,
										}}
									>
										<option value=''>---</option>
										{Object.entries(luna_customization_categories).map((field, index) => (
											<option value={field[0]}>{field[1]}</option>
										))}

									</Select>

								</FormControl>

								<TextField
									inputRef={register({})}
									name={`composition_option[${index}].additional_cost`}
									defaultValue={field.additional_cost}
									label='Supplément €'
									InputProps={{
										endAdornment:<InputAdornment position="end">€</InputAdornment>,
									}}
									style={{ width: '20%' }}
								/>

								<IconButton onClick={() => remove_option(index)}>
									<DeleteIcon fontSize="inherit"/>
								</IconButton>

							</div>
							))}

							<Button type="button" variant="contained" size="small" color="default" onClick={() => append_option({sku: '', additional_cost: '0', category:''})} >
								Ajouter une option
							</Button>

						</div>

					</div>


					<div style={{ paddingBottom: 40 }}>

						<Typography variant='h6' noWrap style={{ paddingBottom: 20 }}><b>Contraintes</b></Typography>

						{Object.entries(luna_customization_categories).map((field, index) => (
						<div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15 }}>

							<div noWrap style={{ paddingBottom: 5, width: 200, textAlign: 'right', marginRight: 10 }}><b>{field[1]}</b></div>

							<TextField
								InputLabelProps={{ shrink: true }}
								inputRef={register()}
								name={'constraints.' + field[0] + '.number'}
								label={"Nombre max"}
								style={{ width: 200, marginRight: 10 }}
							/>

							<Controller
								name={'constraints.' + field[0] + '.mandatory'}
								control={control}
								defaultValue={false}
								render={props =>
									<FormControlLabel
									control={<Checkbox  onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
									label='Obligatoire'
									style={{ width: 170 }}
									/>
								}
							/>

						</div>
						))}
					</div>

					<div style={{ paddingBottom: 40 }}>

						<Typography variant='h6' noWrap style={{ paddingBottom: 20 }}><b>Options :</b></Typography>

						<div style={{ display: 'flex', paddingBottom: 15 }}>
							<FormControl className={classes.formControl} style={{ width: 200 }}>
								<InputLabel htmlFor='heat_up_time'>Temps de chauffe</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'heat_up_time',
										id: 'heat_up_time',
									}}
								>
									<option value='0'></option>
									<option value='1'>Preset 1 - 0'10</option>
									<option value='2'>Preset 2 - 0'20</option>
									<option value='3'>Preset 3 - 0'30</option>
									<option value='4'>Preset 4 - 0'40</option>
									<option value='5'>Preset 5 - 0'50</option>
									<option value='6'>Preset 6 - 1'00</option>
									<option value='7'>Preset 7 - 1'15</option>
									<option value='8'>Preset 8 - 1'30</option>
									<option value='9'>Preset 9 - 1'45</option>
									<option value='10'>Preset 0 - 2'00</option>
								</Select>
							</FormControl>
						</div>

						<div style={{ paddingBottom: 15 }}>

							{fields_size.map((field, index) => (
							<div key={field.id} style={{ display: 'flex', paddingBottom: 15 }}>

								<TextField
									inputRef={register({})}
									name={`size[${index}].label`}
									defaultValue={field.label}
									label='Label'
									style={{ width: '70%', marginRight: 10 }}
								/>

								<TextField
									inputRef={register({})}
									name={`size[${index}].additional_cost`}
									defaultValue={field.additional_cost}
									label='Supplément €'
									InputProps={{
										endAdornment:<InputAdornment position="end">€</InputAdornment>,
									}}
									style={{ width: '30%' }}
								/>

								<IconButton onClick={() => remove_size(index)}>
									<DeleteIcon fontSize="inherit"/>
								</IconButton>

							</div>
							))}

							<Button type="button" variant="contained" size="small" color="default" onClick={() => append_size({ label: '', additional_cost: '0' })} >
								Ajouter une taille
							</Button>

						</div>

					</div>

					{Object.values(luna_products).length > 0 &&
					<div style={{ paddingBottom: 40 }}>

						<Typography variant='h6' noWrap style={{ paddingBottom: 20 }}><b>Cross Sell</b></Typography>

						<div style={{ paddingBottom: 20 }}>

							{fields_cross_sell.map((field, index) => (
							<div key={field.id} style={{ display: 'flex', paddingBottom: 15 }}>

								<Controller
	      						render={props => (
									<Autocomplete
										{...props}

										options={luna_products_list}
										getOptionLabel={(option) => (option.name + ' ' + option.price + '€')}
										getOptionSelected={(option, value) => option.id === value.product_id}

										onChange={(_, data) => props.onChange(data)}

										style={{ width: '55%', paddingRight: 15 }}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="standard"
												label='Option'
												placeholder="Option"
											/>
										)}
									/>
								)}
								defaultValue={luna_products[field.product_id]}
								name={`cross_sell[${index}].product_id`}
								control={control}
								/>

								<TextField
									inputRef={register({})}
									name={`cross_sell[${index}].additional_cost`}
									defaultValue={field.additional_cost}
									label='Supplément €'
									InputProps={{
										endAdornment:<InputAdornment position="end">€</InputAdornment>,
									}}
									style={{ width: '20%' }}
								/>

								<IconButton onClick={() => remove_cross_sell(index)}>
									<DeleteIcon fontSize="inherit"/>
								</IconButton>

							</div>
							))}

							<Button type="button" variant="contained" size="small" color="default" onClick={() => append_cross_sell({ product_id: '', additional_cost: '' })} >
								Ajouter un produit
							</Button>

						</div>

					</div>
					}


					<div style={{ paddingBottom: 40 }}>

						<Typography variant='h6' noWrap style={{ paddingBottom: 20 }}><b>Médias :</b></Typography>

						<label htmlFor='picture_1' style={{ textAlign: 'center' }}>
							<input id='picture_1' type='file' onChange={fileChangedHandler} style={{ display: 'none' }}/>
							<Avatar
								src={picture_1}
								style={{ width: 225, height: 150 }}
								variant='rounded'
								component='span'
							/>
							1
						</label>

						<label htmlFor='picture_2' style={{ marginLeft: 20, textAlign: 'center' }}>
							<input id='picture_2' type='file' onChange={fileChangedHandler} style={{ display: 'none' }}/>
							<Avatar
								src={picture_2}
								style={{ width: 225, height: 150 }}
								variant='rounded'
								component='span'
							/>
							2
						</label>

					</div>

					{current_product.allergens !== undefined &&
					<div style={{paddingBottom : 20}}>
						<Typography variant='h6' noWrap style={{paddingBottom: 5}}><b>Allergènes</b></Typography>
						{current_product.allergens.join(', ')}
					</div>
					}

					{current_product.nutrition !== undefined &&
					<div style={{ paddingBottom: 20 }}>
						<Typography variant='h6' noWrap style={{paddingBottom: 5}}><b>Valeurs nutritionnelles</b></Typography>
						<p style={{ marginBottom: 0 }}>Energie: {current_product.nutrition.kcal}kcal / {current_product.nutrition.kj}kj</p>
						<p style={{ marginBottom: 0 }}>Protéines: {current_product.nutrition.proteins}g</p>
						<p style={{ marginBottom: 0 }}>Lipides: {current_product.nutrition.fat}g</p>
						<p style={{ marginBottom: 0 }}>Dont graisses saturées: {current_product.nutrition.saturated_fat}g</p>
						<p style={{ marginBottom: 0 }}>Glucides: {current_product.nutrition.carbs}g</p>
						<p style={{ marginBottom: 0 }}>Dont sucre: {current_product.nutrition.sugar}g</p>
						<p style={{ marginBottom: 0 }}>Sel: {current_product.nutrition.salt}g</p>
					</div>
					}

					<div style={{ width: 400, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

						<div style={{paddingBottom : 20}}>
							<Typography variant='h6' noWrap style={{paddingBottom: 5}}><b>Poids</b></Typography>
							{current_product.weight}g
						</div>

					</div>

					<div>
						<Button variant="contained" color="default" type='submit'>
							Enregistrer
						</Button>
					</div>

				</form>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default React.memo(LunaProduct);


