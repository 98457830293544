import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableSortLabel, TableRow, Paper, Button, Avatar, IconButton, TextField } from '@material-ui/core';

import { useForm, Controller, useFieldArray } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ShopsContext } from "../store/ShopsProvider";


function CreateInvoice (data) {

	const { generateInvoice } = useContext(ShopsContext);

	const { shop_id, onClose, selectedValue, open } = data;

	const [total_ht, setTotalHt] = useState(0);
	const [total_ttc, setTotalTtc] = useState(0);

	const { register, handleSubmit, watch, control, reset, setValue, errors} = useForm({
		defaultValues : {}
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'items'
	});

	const items = watch('items')


	const handleClose = () => {
		onClose(selectedValue);
	};


	useEffect(() => {

		var ht = 0;
		var ttc = 0;

		for (var row of items) {

			var total_ht = parseFloat(row.total_ht);
			if (isNaN(total_ht)) continue;

			ht += total_ht;
			ttc += total_ht + (total_ht * parseFloat(row.tva) / 100);
		};

		setTotalHt(ht);
		setTotalTtc(ttc);

	}, [items]);


	const onSubmit = (raw_data) => {

		var data = raw_data;

		generateInvoice(data.type, shop_id, data.items, () => {
			handleClose(true);
		});

		return true;
	};


	return (
		<Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth='lg' scroll='paper'>

			<form onSubmit={handleSubmit(onSubmit)}>

				<DialogTitle>
					<h2>Nouvelle facture</h2>
				</DialogTitle>

				<DialogContent dividers={true}>

					<div style={{ marginBottom: 20 }}>
						<FormControl style={{ width: '30%' }}>
							<InputLabel>Type</InputLabel>
							<Select
								inputRef={register()}
								native
								inputProps={{
									name: 'type',
								}}
								InputLabelProps={{ shrink: true }}
							>
								<option value={'credit'}>Avoir</option>
								<option value={'invoice'}>Facture</option>
							</Select>
						</FormControl>

					</div>

					<Table>

						<TableHead>
							<TableRow>
								<TableCell width='50%' style={{ paddingLeft: 0 }}>Label</TableCell>
								<TableCell width='22%'>Montant HT</TableCell>
								<TableCell width='22%'>TVA</TableCell>
								<TableCell width='6%'></TableCell>
							</TableRow>
						</TableHead>

						<TableBody>

								{fields.map((field, index) => (
								<StyledTableRow key={field.id}>
									<TableCell width='50%'>
										<TextField
											inputRef={register({
												required: true
											})}
											name={`items.${index}.label`}
											label='Label'
											defaultValue={''}
											style={{ width: '100%' }}
										/>
									</TableCell>

									<TableCell width='22%'>
										<TextField
											inputRef={register({
												required: true,
												validate: {
													positive: (value) => {
														return !isNaN(parseFloat(value))
													}
												},
												setValueAs: value => parseFloat(value)
											})}
											name={`items.${index}.total_ht`}
											defaultValue={''}
											label='Montant HT'
											style={{ width: '100%' }}
											error={errors.items !== undefined && errors.items[index] !== undefined && errors.items[index].total_ht ? true : false}
										/>
									</TableCell>

									<TableCell width='22%'>
										<FormControl style={{ width: '100%' }}>
											<InputLabel>TVA</InputLabel>
											<Select
												inputRef={register({
													setValueAs: value => parseFloat(value)
												})}
												native
												inputProps={{
													name: `items.${index}.tva`,
												}}
												InputLabelProps={{ shrink: true }}
											>
												<option value={'5.5'}>5,5%</option>
												<option value={'10'}>10%</option>
												<option value={'20'}>20%</option>
											</Select>
										</FormControl>
									</TableCell>

									<TableCell width='6%'>
										<IconButton onClick={() => remove(index)}>
											<DeleteIcon fontSize="inherit"/>
										</IconButton>
									</TableCell>

								</StyledTableRow>
								))}

						</TableBody>
					</Table>

					<div>
						<Button style={{ marginTop: 10 }} variant='contained' color='default' onClick={() => append({})}>
							+
						</Button>
					</div>

				</DialogContent>

				<DialogActions style={{ padding: 16, color: 'white', backgroundColor: 'darkslateblue' }}>

					<div>
						<b>Total</b>&nbsp;&nbsp;{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total_ht)} HT | {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total_ttc)} TTC
					</div>

					<Button disabled={total_ht === 0} type='submit' variant='contained' color='primary' size="small">
						Créer la Facture
					</Button>

				</DialogActions>

			</form>

		</Dialog>
	);
};

export default React.memo(CreateInvoice);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

