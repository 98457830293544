import React, { useContext, useEffect, useState } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import 'firebase/compat/functions'

import { UsersContext } from '../store/UsersProvider';
import { Sort } from '.././Utils';

import { DateTime } from "luxon";

export const ClientsContext = React.createContext({});

function ClientsProvider({ children }) {


	const { user, checkUserRights } = useContext(UsersContext);


	const getClient = (id, callback, snapshot = true) => {
		if (snapshot) return firebase.firestore().collection('clients').doc(id).onSnapshot(callback);
		return firebase.firestore().collection('clients').doc(id).get().then((data) => callback(data));
	};


	const getClients = (callback) => {

		const db = firebase.firestore();
		db.collection('clients').get().then((querySnapshot) => {
			const clients = [];
			querySnapshot.forEach((doc) => {
				clients.push(doc.data());
			});
			callback(clients);
		}).catch((error) => {
			console.error("Erreur lors du chargement des clients: ", error);
		});
	};


	const getShopsByClient = (client_id, callback) => {
		firebase.firestore().collection('shaki_shops').where('client_id', '==', client_id).get().then((querySnapshot) => {
			const shops = [];
			querySnapshot.forEach((doc) => {
				shops.push(doc.data());
			});
			callback(shops);
		});
	};


	const createClient = (data, callback = null) => {

		console.log('CreateClient', data, user);

		data.__meta = {
			creation_date: firebase.firestore.FieldValue.serverTimestamp(),
			created_by: user.uid
		};

		var doc = firebase.firestore().collection('clients').doc();
		data.id = doc.id;

		doc.set(data).then(() => {
			if (callback !== null) callback(doc.id);
		}).catch((error) => {
			console.error('Error adding clients: ', error);
		});
	};


	const updateClient = (id, data, callback = null) => {

		console.log('UpdateClient', id, data);

		firebase.firestore().collection('clients').doc(id).update({
			'__meta.update_date': firebase.firestore.FieldValue.serverTimestamp(),
			'__meta.updated_by': user.uid,
			...data
		}).then((doc) => {
			if (callback !== null) callback();
		}).catch((error) => {
			console.error('Error updating clients: ', error);
		});
	};


	return(
		<ClientsContext.Provider value={{

			getClients: getClients,
			getClient: getClient,
			getShopsByClient: getShopsByClient,

			createClient: createClient,
			updateClient: updateClient

		}}>
			{children}
		</ClientsContext.Provider>
	);
}

export default React.memo(ClientsProvider);
