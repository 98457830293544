import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { DateTime } from "luxon";

var cache = {}

function UpdateUser (id, data, user, callback = null) {

	console.log('UpdateUser', id, data, user);

	firebase.firestore().collection('users').doc(id).update({
		'__meta.update_date' : firebase.firestore.FieldValue.serverTimestamp(),
		'__meta.updated_by' : user.uid,
		...data
	}).then(function(doc) {
		if (callback !== null) callback();
	}).catch(function(error) {
		console.error('Error updating users: ', error);
	});
}

///////////
// Order //
///////////

function AddOrder (data, user, callback = null) {

	console.log('AddOrder', data, user);

	data.__meta = {
		creation_date : firebase.firestore.FieldValue.serverTimestamp(),
		created_by : user.uid
	}

	var doc = firebase.firestore().collection('shaki_orders').doc();
	data.id = doc.id;

	doc.set(data).then(function() {

		if (callback !== null) callback();

	}).catch(function(error) {
		console.error('Error adding shaki_orders: ', error);
	});
}

function UpdateOrder (id, data, user, callback = null) {

	console.log('UpdateOrder', id, data, user);

	firebase.firestore().collection('shaki_orders').doc(id).update({
		'__meta.update_date' : firebase.firestore.FieldValue.serverTimestamp(),
		'__meta.updated_by' : user.uid,
		...data
	}).then(function(doc) {
		if (callback !== null) callback();
	}).catch(function(error) {
		console.error('Error updating shaki_orders: ', error);
	});
}

function DeleteOrder (id, callback = null) {

	console.log('DeleteOrder', id);

	firebase.firestore().collection('shaki_orders').doc(id).delete();
	if (callback !== null) callback();
}


/////////////
// Product //
/////////////

function UpdateProduct (id, data, user, callback = null) {

	console.log('UpdateProduct', id, data, user);

	firebase.firestore().collection('shaki_products').doc(id).update({
		'__meta.update_date' : firebase.firestore.FieldValue.serverTimestamp(),
		'__meta.updated_by' : user.uid,
		...data
	}).then(function(doc) {
		if (callback !== null) callback();
	}).catch(function(error) {
		console.error('Error updating shaki_products: ', error);
	});
};


function DeleteProduct (id, callback = null) {

	console.log('DeleteProduct', id);

	firebase.firestore().collection('shaki_products').doc(id).delete();
	if (callback !== null) callback();
};


//////////////
// Invoices //
//////////////

function sendEmailInvoice (id, user) {

	console.log('isPaidInvoice', id, user);

	firebase.firestore().collection('invoices').doc(String(id)).update({
		'__meta.update_date' : firebase.firestore.FieldValue.serverTimestamp(),
		'__meta.updated_by' : user.uid,
		emailed : firebase.firestore.FieldValue.arrayUnion(DateTime.local().toSeconds())
	}).then((doc) => {

	}).catch((error) => {
		console.error('Error updating invoices: ', error);
	});
};


function isPaidInvoice (id, date, user) {

	console.log('isPaidInvoice', id, user);

	firebase.firestore().collection('invoices').doc(String(id)).update({
		'__meta.update_date': firebase.firestore.FieldValue.serverTimestamp(),
		'__meta.updated_by': user.uid,
		paid: date.toSeconds()
	}).then((doc) => {

	}).catch((error) => {
		console.error('Error updating invoices: ', error);
	});
};


function isUnPaidInvoice (id, user) {

	console.log('isUnPaidInvoice', id, user);

	firebase.firestore().collection('invoices').doc(String(id)).update({
		'__meta.update_date': firebase.firestore.FieldValue.serverTimestamp(),
		'__meta.updated_by': user.uid,
		paid: false
	}).then((doc) => {

	}).catch((error) => {
		console.error('Error updating invoices: ', error);
	});
};


//////////
// Home //
//////////

function GetUnpaidInvoices (callback) {
	return firebase.firestore().collection('invoices').where('paid', '==', false).onSnapshot(callback);
};

function GetOrders (date, callback) {
	return firebase.firestore().collection('shaki_orders').where('day_prod', '==', parseInt(date.toFormat('yyyyLLdd'))).onSnapshot(callback);
};


//////////
// Shop //
//////////

function CreateShop (data, user, callback = null) {

	console.log('CreateShop', data, user);

	data.__meta = {
		creation_date : firebase.firestore.FieldValue.serverTimestamp(),
		created_by : user.uid
	}

	var doc = firebase.firestore().collection('shaki_shops').doc();
	data.id = doc.id;

	doc.set(data).then(() => {
		if (callback !== null) callback(doc.id);
	}).catch((error) => {
		console.error('Error adding shaki_orders: ', error);
	});
};


function UpdateShop (id, data, user, callback = null) {

	console.log('UpdateShop', id, data, user);

	firebase.firestore().collection('shaki_shops').doc(id).update({
		'__meta.update_date' : firebase.firestore.FieldValue.serverTimestamp(),
		'__meta.updated_by' : user.uid,
		...data
	}).then((doc) => {
		if (callback !== null) callback();
	}).catch((error) => {
		console.error('Error updating shaki_shops: ', error);
	});
};


////////////
// Routes //
////////////

function getRoutesConf (id, callback) {

	if (cache[id] !== undefined) return callback(cache[id]);

	firebase.firestore().collection('routes_config').doc(id).get().then((doc) => {
		cache[id] = doc;
		callback(doc)
	});

	return true;
};


function getRoutesConfs (callback) {

	firebase.firestore().collection('routes_config').where('day', '!=', '').onSnapshot((docs) => {
		callback(docs)
	});

	return true;
};


function setRoutesConf (id, data) {
	console.log('setRoutesConf', id, data);
	return firebase.firestore().collection('routes_config').doc(id).set(data);
};


function getRoutesByOrderId (id, callback = null) {

	console.log('getRoutesByOrderId', id);

	firebase.firestore().collection('routes').where('orders', 'array-contains', id).onSnapshot(callback);
};


const getBankTransactions = (year, month, callback) => {
	console.log(parseInt(String(year) + String(month).padStart('0', 2) + '01'));
	return firebase.firestore().collection('bank_transactions').where('date', '>=', parseInt(String(year) + String(month).padStart(2, '0') + '01') ).where('date', '<=', parseInt(String(year) + String(month).padStart(2, '0') + '31')).orderBy('date', 'asc').onSnapshot(callback);
};


export {
	UpdateUser,
	AddOrder, UpdateOrder, DeleteOrder,
	UpdateProduct, DeleteProduct,
	sendEmailInvoice, isPaidInvoice, isUnPaidInvoice, GetUnpaidInvoices,
	GetOrders,
	CreateShop, UpdateShop,
	getRoutesConf, getRoutesConfs, setRoutesConf, getRoutesByOrderId,
	getBankTransactions
};
