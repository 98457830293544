import React, { useContext, useEffect, useState } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { DateTime } from "luxon";
import { Sort } from '.././Utils';

import { TimeContext } from '../store/TimeProvider';
import { UsersContext } from '../store/UsersProvider';
import { ShakiContext } from '../store/ShakiProvider';
import { ShopsContext } from '../store/ShopsProvider';

export const OrdersContext = React.createContext({});

function OrdersProvider({ children }) {

	const { products } = useContext(ShakiContext);
	const { user, checkUserRights } = useContext(UsersContext);
	const { day_prod } = useContext(TimeContext);
	const { shops, GetCurrentPriceList, GetCurrentRateCard } = useContext(ShopsContext);

	const [orders, setOrders] = useState([]);
	//const [orders_preparation_hash, setOrdersPreparationHash] = useState({});
	const [routes_order, setRoutesOrder] = useState({});

	const [order_id, setOrderId] = useState(null);

	const [order, setOrder] = useState(null);
	const [order_preparation, setOrderPreparation] = useState({});
	const [order_routes, setOrderRoutes] = useState([]);
	const [count, setCount] = useState(0);


	const setCurrentOrder = (id) => {
		if (order_id === id) return;
		return setOrderId(id);
	}


	const refresh = () => {
		var c = count + 1
		setCount(c);
	}


	const GetOrder = (id, callback) => {

		if (shops === null) return;

		return firebase.firestore().collection('shaki_orders').doc(id).onSnapshot((doc) => {

			var data = doc.data();
			data.shop = shops[data.shop_id];
			data.pricelist = GetCurrentPriceList(data.shop.pricelist_group_id, data.day);
			data.rate_card = GetCurrentRateCard(data.day);
			callback(data);
		});
	}

	/*
	const GetOrderPreparation = (order, callback) => {

		return firebase.firestore().collection('shaki_orders_preparation').doc(order.id).onSnapshot((doc) => {

			if (doc.exists === false) {
				firebase.firestore().collection('shaki_orders_preparation').doc(order.id).set({
					id: order.id,
					container_type: order.container_type,
					day_prod: order.day_prod,
					containers: [],
					complete: false
				});
			} else {
				callback(doc);
			}
		});
	}
	*/

	useEffect(() => {

		if (order_id === null) return;
		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders').doc(order_id).onSnapshot((doc) => {

			var order = doc.data();

			if (order.products === undefined) {
				order.products = {};
				for (var i in order.productList) {
					order.products[products[order.productList[i].product_id].sku] = order.productList[i].amount;
				}
			}

			setOrder(order);
		});

		//return () => subscriber();

	}, [order_id, user]);


	/*
	useEffect(() => {

		if (order === null) return;
		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders_preparation').doc(order.id).onSnapshot((doc) => {

			if (doc.exists === false) {
				firebase.firestore().collection('shaki_orders_preparation').doc(order.id).set({
					id: order.id,
					container_type: order.container_type,
					day_prod: order.day_prod,
					containers: [],
					complete: false
				});
			} else {
				setOrderPreparation(doc.data());
			}
		});

		//return () => subscriber();

	}, [order, user]);
	*/


	const GetOrderRoutes = (id, callback) => {

		if (id === null) return;
		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes').where('orders', 'array-contains', id).onSnapshot((querySnapshot) => {

			const list = [];

			if (querySnapshot === null) callback(list);

			querySnapshot.forEach(doc => {
				list.push(doc.data());
			});

			callback(list.sort((a, b) => Sort(a, b, 'step', 'asc')));
		});
	};


	/*
	const isCompleteOrder = (order_id) => {
		return (orders_preparation_hash[order_id] !== undefined && orders_preparation_hash[order_id].complete !== undefined) ? orders_preparation_hash[order_id].complete : false;
	}
	*/


	/*
	const completeOrder = (order_id, linked_containers, callback = null) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders_preparation').doc(order_id).update({
			containers : linked_containers,
			complete : true,
			saved_date : Math.round(new Date().getTime() / 1000),
			saved_by : user.id
		});
	}
	*/


	const startRoute = (route_id) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes').doc(route_id).update({
			state: 1
		});
	};

	const completeRoute = (route_id) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes').doc(route_id).update({
			state: 2,
			completed_at: {
				time: DateTime.local().toSeconds()
			}
		});
	}


	/*
	const markAsDoneProductOrder = (order_id, product_id, value) => {

		if (!checkUserRights()) return;

		var obj = {};
		obj["products." + String(product_id)] = value;

		return firebase.firestore().collection('shaki_orders_preparation').doc(order_id).update(obj);
	}
	*/


	const saveDelivered = (id, delivered) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders').doc(id).update({
			delivered: delivered
		});
	}


	const saveCredited = (id, credited) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders').doc(id).update({
			credited: credited
		});
	}


	const generateInvoice = (order_id) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('invoices_queue').doc(order_id).set({
			type: 'invoice_order',
			year: DateTime.local().year,
			order_id: order_id
		});
	};


	const generateDeliveryFile = (route_id, order_id) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes').doc(route_id).update({
			generate_delivery_file: order_id
		});
	};


	useEffect(() => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders').where('day_prod', '==', day_prod).onSnapshot((querySnapshot) => {

			const list = [];
			querySnapshot.forEach(doc => {
				list.push(doc.data());
			});

			setOrders(list);
		});

		//return () => {console.log('unmount'); subscriber()};

	}, [user, day_prod]);


	/*
	useEffect(() => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders_preparation').where('day_prod', '==', day_prod).onSnapshot((querySnapshot) => {

			const hash = {};
			querySnapshot.forEach(doc => {
				hash[doc.id] = doc.data();
			});

			setOrdersPreparationHash(hash);
		});

		//return () => subscriber();

	}, [day_prod, user]);
	*/

	useEffect(() => {

		if (!checkUserRights()) return;
		if (orders.length === 0) return;

		return (() => {

			var nb = 10;
			var p = [];

			for (var i = 0; i < Math.ceil(orders.length / nb); i++) {

				var selected_orders = orders.slice((i * nb), (i + 1) * 10);

				var ids = [];
				for (var j in selected_orders) {
					ids.push(selected_orders[j].id);
				}

				p.push(firebase.firestore().collection('routes').where('orders', 'array-contains-any', ids).get());
			}

			Promise.all(p).then((values) => {

				var hash = {};
				var list = {};

				for (var i in values) {

					values[i].forEach((doc) => {

						for (var k in doc.data().orders) {

							var id = doc.data().orders[k];
							if (hash[id] === undefined) {
								hash[id] = {};
								list[id] = [];
							}

							if (hash[id][doc.data().id] === undefined) {
								hash[id][doc.data().id] = doc.data();
								list[id].push(doc.data());
							}
						}
					});
				}

				setRoutesOrder(list);
			});

		})();


	}, [orders, user, count]);

	return(
		<OrdersContext.Provider value={{

			GetOrder: GetOrder,
			//GetOrderPreparation: GetOrderPreparation,
			GetOrderRoutes: GetOrderRoutes,

			orders : orders,
			//orders_preparation_hash : orders_preparation_hash,
			routes_order : routes_order,

			order : order,
			order_preparation : order_preparation,
			order_routes : order_routes,

			setCurrentOrder : setCurrentOrder,
			//isCompleteOrder : isCompleteOrder,
			//completeOrder : completeOrder,
			//markAsDoneProductOrder : markAsDoneProductOrder,
			refresh : refresh,

			saveDelivered: saveDelivered,
			saveCredited: saveCredited,

			generateInvoice: generateInvoice,
			generateDeliveryFile: generateDeliveryFile,
			startRoute: startRoute,
			completeRoute: completeRoute
		}}>
			{children}
		</OrdersContext.Provider>
	);
}

export default OrdersProvider;
