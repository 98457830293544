import React from 'react';

import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Checkbox, Typography, Select, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { Link } from "react-router-dom";
import { Controller, useFieldArray } from "react-hook-form";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import { CleanNumber } from '.././Utils';

function PriceForm(row) {

	const classes = useStyles();

	var [discount, setDiscount] = useState(row.pricelist !== undefined && row.pricelist.discount !== undefined ? row.pricelist.discount : 0);

	const { fields, append, remove } = useFieldArray({
		control: row.control,
		name: 'prices.' + row.sku + '.discounts'
	});

	const [display_price, setDisplayPrice] = useState(row.pricelist !== undefined && row.pricelist.override_price !== undefined ? row.pricelist.override_price : row.rate_card.prices[row.sku]);

	return (
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 70, marginLeft: 10, width: '100%' }}>
			{row.is_rate_card ?
				<>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<div style={{ width: 80 }}>
						<Typography variant="body2">
							<Link to={'/products/' + row.id}>{row.sku}</Link>
						</Typography>
					</div>

					<div style={{ width: 350 }}>
						<Typography variant="body2">
							{row.name}
						</Typography>
					</div>
				</div>

				<TextField
					inputRef={row.register()}
					name={'prices.' + row.sku }
					label='Prix H.T.'
					type='number'
					InputProps={{
						inputProps: {
							min: 0,
							step: .01
						},
						endAdornment: <InputAdornment position='start'>€</InputAdornment>
					}}
					style={{ width: 100 }}
				/>
				</>
			:
			<>
			<div style={{ width: 42 }}>

				<TextField hidden inputRef={row.register()} name={'prices.' + row.sku + '.sku'} value={row.sku} />

				<Controller
					name={'prices.' + row.sku + '.enabled'}
					control={row.control}
					defaultValue={false}
					render={({ onChange, onBlur, value, name, ref }) => (
						<Checkbox
							onBlur={onBlur}
							onChange={(e) => onChange(e.target.checked)}
							checked={value}
							inputRef={ref}
						/>
					)}
				/>

			</div>

			<div style={{ width: 90 }}>
				<Typography variant="body2">
					<Link to={'/products/' + row.id}>{row.sku}</Link>
				</Typography>
			</div>

			<TextField
				inputRef={row.register()}
				name={'prices.' + row.sku + '.internal_reference'}
				label='Réf interne'
				InputLabelProps={{
					shrink: true,
				}}
				style={{ width: 90, marginLeft: 10 }}
			/>

			<div style={{ width: 200, marginLeft: 10 }}>
				<Typography variant="body2" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
					{row.name}
				</Typography>
			</div>

			{row.type !== 'revshare' &&
			<div style={{ width: 50 }}>
				<Typography variant="body2" style={{ color: 'grey' }}>
					{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(CleanNumber(display_price * (100 - discount) / 100))}
				</Typography>
			</div>
			}

			{(row.override_prices && row.type !== 'revshare') &&
			<TextField
				inputRef={row.register()}
				name={'prices.' + row.sku + '.override_price'}
				label='prix'
				type='number'
				onChange={(e) => {
					setDisplayPrice(parseFloat(e.target.value))
				}}
				InputProps={{
					inputProps: {
						min: 0,
						step: .01
					},
					endAdornment: <InputAdornment position="start">€</InputAdornment>
				}}
				InputLabelProps={{
					shrink: true,
				}}
				style={{ width: 70, marginLeft: 10 }}
			/>
			}


			<FormControl className={classes.formControl} style={{ width: 60, minWidth: 60, marginLeft: 10 }}>
				<InputLabel htmlFor="TVA">Tva</InputLabel>
				<Select
					inputRef={row.register()}
					native
					name={'prices.' + row.sku + '.tva_percent'}
					inputProps={{
						id: 'prices_' + row.sku + '_tva_percent',
						name: 'prices.' + row.sku + '.tva_percent'
					}}
					style={{ width: 60 }}
				>
					<option value={5.5}>5.5%</option>
					<option value={10}>10%</option>
				</Select>
			</FormControl>


			<div style={{ display : 'flex', flexDirection: 'row', marginLeft: 10 }}>

				{row.type !== 'revshare' ?

					<>
					<TextField
						inputRef={row.register()}
						id={'prices.' + row.sku + '.discount'}
						name={'prices.' + row.sku + '.discount'}
						label='Remise'
						type='number'
						InputProps={{
							inputProps: {
								min: 0,
								step: .01
							},
							endAdornment: <InputAdornment position='start'>%</InputAdornment>
						}}
						InputLabelProps={{
							shrink: true,
						}}
						onChange={(e) => {
							setDiscount((e.target.value === '') ? 0 : parseFloat(e.target.value))
						}}
						style={{ width: 70 }}
					/>

					{fields.map((field, index) => (
					<div key={row.sku + '_discounts_' + index} style={{ display: 'flex', flexDirection: 'row', border: "solid 1px grey", borderRadius: 5, marginLeft: 5, padding: 5 }}>

						<TextField
							inputRef={row.register()}
							name={'prices.' + row.sku + `.discounts[${index}].range`}
							label='Palier'
							defaultValue={field.range}
							type="number"
							InputProps={{
								inputProps: {
									min: 0,
									step: 5
								},
							}}
							style={{ width: 45 }}
						/>

						<TextField
							inputRef={row.register()}
							name={'prices.' + row.sku + `.discounts[${index}].discount`}
							label='Remise'
							defaultValue={field.discount}
							type='number'
							InputProps={{
								inputProps: {
									min: 0,
									step: .01
								},
								endAdornment: <InputAdornment position='start'>%</InputAdornment>
							}}
							style={{ width: 45, marginLeft: 5 }}
						/>

						<IconButton onClick={() => remove(index)}>
							<DeleteIcon fontSize="inherit"/>
						</IconButton>

					</div>
					))}

					<IconButton onClick={() => append({ discount: 0, range: 100 })}>
						<AddIcon fontSize="inherit"/>
					</IconButton>
					</>
				:
					<TextField
						inputRef={row.register()}
						id={'prices.' + row.sku + '.retail_price'}
						name={'prices.' + row.sku + '.retail_price'}
						label='PVC'
						type='number'
						InputProps={{
							inputProps: {
								min: 0,
								step: .01
							},
							endAdornment: <InputAdornment position="start">€</InputAdornment>
						}}
						style={{ width: 90 }}
					/>
				}
			</div>
			</>
			}
		</div>
	);
}

export default React.memo(PriceForm);

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 80
	}
}));
