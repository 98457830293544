import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { Paper, Typography, Breadcrumbs, Tabs, Tab } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";

import { ClientsContext } from "../store/ClientsProvider";

function ClientHeader(data) {

    const { getClient } = useContext(ClientsContext);

    const [current_client, setCurrentClient] = useState({});

    var { client_id } = data;
    var history = useHistory();
    var action = client_id === 'new' ? 'create' : 'update';


    useEffect(() => {

        if (client_id === 'new') return;

        getClient(client_id, (doc) => {
            var data = doc.data();
            setCurrentClient(data);
        });

    }, [client_id]);


    const handleChange = (event, newValue) => {
        if (newValue === 0) history.push('/clients/' + data.client_id);
        if (newValue === 1) history.push('/clients/' + data.client_id + '/shops');
    };


	return (
        <div>
            <div style={{ padding: 20 }}>

                <Breadcrumbs aria-label="breadcrumb">
                    <Link to='/'>Accueil</Link>
                    <Link to='/clients'>Clients</Link>
                    <Typography>{current_client.legal_entity}</Typography>
                </Breadcrumbs>

                <h2>{current_client.legal_entity}</h2>
            </div>

            {action === 'update' &&
            <Paper elevation={0}>
                <Tabs value={data.tab_value} onChange={handleChange} style={{ borderBottom: '1px solid #999999' }}>
                    <Tab label='Profil' />
                    <Tab label='Points de vente' />
                </Tabs>
            </Paper>
            }

        </div>
	);
};

export default React.memo(ClientHeader);


