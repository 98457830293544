import React, { useContext, useEffect, useState } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import 'firebase/compat/functions'


import { ShakiContext } from '../store/ShakiProvider';
import { TimeContext } from '../store/TimeProvider';
import { UsersContext } from '../store/UsersProvider';
import { Sort } from '.././Utils';

import { DateTime } from "luxon";

export const ShopsContext = React.createContext({});

function ShopsProvider({ children }) {

	const { getRemoteConf, tags } = useContext(ShakiContext);
	const { user, checkUserRights } = useContext(UsersContext);
	const { today } = useContext(TimeContext);

	const [shop_id, setShopId] = useState(null);
	const [shop, setShop] = useState(null);

	const [shop_unsold, setShopUnsold] = useState(null);
	const [shops, setShops] = useState(null);
	const [price_list, setPriceList] = useState({});
	const [price_list_groups, setPriceListGroups] = useState({});

	const economic_models = {
		firm_sale: 'Vente ferme',
		revshare: 'Concession',
		consignment: 'Dépôt vente',
		free: 'Pas de facturation'
	};


	const setCurrentShop = (id) => {
		if (shop_id === id) return;
		setShopUnsold(null);
		setShopId(id);
	};


	const GetShop = (id, callback, snapshot = true) => {
		if (snapshot) return firebase.firestore().collection('shaki_shops').doc(id).onSnapshot(callback);
		return firebase.firestore().collection('shaki_shops').doc(id).get().then((data) => callback(data));
	};


	const GetProductionTemplate = (id, callback) => {
		return firebase.firestore().collection('production_templates').doc(id).onSnapshot(callback);
	};


	useEffect(() => {

		if (!checkUserRights()) return;
		if (tags.length === 0) return;

		return firebase.firestore().collection('shaki_shops').onSnapshot((docs) => {

			var hash = {};
			docs.forEach((doc) => {

				var data = doc.data();
				data.search_index = data.name.toLowerCase() + ' ' + data.key.toLowerCase() + ' ';

				if (data.contact_accounting !== undefined && data.contact_accounting.legal_entity !== undefined) data.search_index += data.contact_accounting.legal_entity.toLowerCase() + ' ';

				if (data.tags !== undefined) {
					for (var tag of data.tags) {
						data.search_index += tags[tag].title.toLowerCase() + ' ';
					};
				};

				hash[doc.id] = data;
			});

			setShops(hash);
		});

	}, [user, tags]);


	///////////////
	// Pricelist //
	///////////////

	useEffect(() => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('pricelists_groups').orderBy('name').onSnapshot((docs) => {

			var hash = {};
			docs.forEach(doc => {
				hash[doc.id] = doc.data();
			});
			setPriceListGroups(hash);
		});

	}, [user]);


	useEffect(() => {

		if (!checkUserRights()) return;
		if (Object.values(price_list_groups).length === 0) return;

		return firebase.firestore().collection('shaki_pricelists').onSnapshot((docs) => {

			var hash = {};
			var list = [];
			var hash_group = {};

			docs.forEach((doc) => {

				var data = doc.data();

				data.field_sort = '';
				if (data.pricelist_group_id !== undefined) data.field_sort += data.pricelist_group_id + ' ';
				if (data.date_start !== undefined) data.field_sort += data.date_start;
				data.field_sort = data.field_sort.trim();

				list.push(data);
			});

			list = list.sort((a, b) => Sort(a, b, 'field_sort', 'asc'));

			var today = parseInt(DateTime.local().toFormat('yyyyLLdd'));
			var reversed = list.reverse();

			for (var i in reversed) {

				var row = reversed[i];

				if (row.pricelist_group_id === undefined || row.date_start === undefined) {
					hash[row.id] = row;
					continue;
				};

				if (hash_group[row.pricelist_group_id] === undefined && row.date_start <= today) {
					row.current = true;
					hash_group[row.pricelist_group_id] = row.id;
				};


				hash[row.id] = row;
			};

			setPriceList(hash);
		});

	}, [user, price_list_groups]);


	const CreatePricelistGroup = (title, callback = null) => {

		var doc = firebase.firestore().collection('pricelists_groups').doc();
		doc.set({
			id: doc.id,
			name: title.trim(),
		}).then(() => {
			CreatePricelist(doc.id, title, callback);
		}).catch((error) => {
			console.error('Error Create Luna Product: ', error);
		});

		return true;
	};


	const CreatePricelist = (pricelist_group_id, title = '', callback = null) => {

		var doc = firebase.firestore().collection('shaki_pricelists').doc();
		doc.set({
			id: doc.id,
			pricelist_group_id: pricelist_group_id,
			name: title,
			date_start: today,
			prices: {}
		}).then(() => {
			if (callback !== null) callback(doc.id);
		}).catch((error) => {
			console.error('Error Create Luna Product: ', error);
		});

		return true;
	};


	const DuplicatePricelist = (obj, callback = null) => {

		delete obj.id;
		delete obj.field_sort;
		delete obj.current;

		var doc = firebase.firestore().collection('shaki_pricelists').doc();
		obj.id = doc.id;
		obj.name = obj.name + ' - copie';
		doc.set(obj).then(() => {
			if (callback !== null) callback(obj);
		}).catch((error) => {
			console.error('Error Create Luna Product: ', error);
		});

		return true;
	};


	const GetCurrentRateCard = (date = null) => {
		return GetCurrentPriceList('ot5CoKAvAbx6ByRT0H1l', date);
	};


	const GetCurrentPriceList = (pricelist_group_id, date) => {

		if (date === null) date = parseInt(DateTime.local().toFormat('yyyyLLdd'));

		// lookup
		var list = [];
		for (var i in price_list) {
			var row = price_list[i];
			if (row.pricelist_group_id !== undefined && row.pricelist_group_id === pricelist_group_id) list.push(row);
		}

		list = list.sort((a, b) => Sort(a, b, 'field_sort', 'asc'));

		var reversed = list.reverse();

		for (var i in reversed) {

			var row = reversed[i];

			if (row.pricelist_group_id === undefined || row.date_start === undefined) continue;

			if (row.date_start <= date) {
				return row;
			}
		}

		return {prices: {}};
	};


	const GetShopsByPricelist = (id, callback) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_shops').where('pricelist_group_id', '==', id).onSnapshot((querySnapshot) => {

			const list = [];
			if (querySnapshot === null) callback(list);

			querySnapshot.forEach(doc => {
				list.push(doc.data());
			});

			callback(list.sort((a, b) => Sort(a, b, 'name', 'asc')));
		});
	};


	////////////
	// Unsold //
	////////////

	useEffect(() => {

		if (!checkUserRights()) return;
		if (shop === null) return;

		var unsold_id = shop.key + '_' + DateTime.local().toFormat('LL_yyyy');

		return firebase.firestore().collection('shaki_unsold').doc(unsold_id).onSnapshot((doc) => {

			if (doc.exists === false) {
				return firebase.firestore().collection('shaki_unsold').doc(unsold_id).set({});
			}

			if (doc.data()[DateTime.local().toFormat('dd')] !== undefined) {
				setShopUnsold(doc.data()[DateTime.local().toFormat('dd')]);
			} else {
				setShopUnsold({});
			}
		});

		//return () => subscriber();

	}, [shop, user]);


	const saveUnsold = (shop, data) => {

		var unsold_id = shop.key + '_' + DateTime.local().toFormat('LL_yyyy');

		var obj = {};
		obj[String(new Date().getDate()).padStart(2, '0')] = data;

		firebase.firestore().collection('shaki_unsold').doc(unsold_id).update({
			'__meta.update_date' : firebase.firestore.FieldValue.serverTimestamp(),
			'__meta.updated_by' : user.uid,
			...obj
		});
	};


	///////////////
	// Invoicing //
	///////////////


	const generateInvoice = (type, shop_id, items, callback = null) => {

		var doc = firebase.firestore().collection('invoices_queue').doc();

		return doc.set({
			type: type,
			shop_id: shop_id,
			items: items
		}).then(() => {
			if (callback !== null) callback(true);
		}).catch((error) => {});
	};


	const GetInvoice = (id, callback = null) => {
		console.log('GetInvoice', id);
		return firebase.firestore().collection('invoices').doc(id).get().then((data) => callback(data.data()));
	};


	const GetInvoices = (shop_id, callback = null) => {

		console.log('GetInvoices', shop_id);

		if (shop_id === 'all') {
			return firebase.firestore().collection('invoices').onSnapshot(callback);
		} else {
			return firebase.firestore().collection('invoices').where('shop_id', '==', shop_id).onSnapshot(callback);
		};
	};


	const regenerateInvoice = (id, onSuccess = () => {}, onError = () => {}) => {

		console.log('regenerateInvoice', id);

		return firebase.app().functions('europe-west1').httpsCallable('invoices-regenerateInvoice')({
			invoice_id: id
		}).then((response) => {
			onSuccess(response);
		}).catch((error) => {
			onError(error);
		});
	};


	const deleteInvoice = (id, callback = null) => {

		console.log('deleteInvoice', id);

		firebase.firestore().collection('invoices').doc(String(id)).update({
			'__meta.delete_date': firebase.firestore.FieldValue.serverTimestamp(),
			'__meta.deleted_by': user.uid,
			deleted: true
		}).then((doc) => {
			if (callback !== null) callback(doc);
		}).catch((error) => {
			console.error('Error updating invoices: ', error);
		});
	};


	////////////////
	// Planograms //
	////////////////

	const GetPlanogram = (planogram_id, callback = null) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('planograms').doc(planogram_id).onSnapshot((doc) => {
			var data = doc.data();
			callback(data);
		});
	};


	const GetPlanograms = (shop_id, callback = null) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('planograms').where('shop_id', '==', shop_id).onSnapshot((querySnapshot) => {

			const list = [];
			if (querySnapshot === null) callback(list);

			querySnapshot.forEach(doc => {
				list.push(doc.data());
			});

			callback(list.sort((a, b) => Sort(a, b, 'name', 'asc')));
		});
	};


	const DuplicatePlanogram = (obj, callback = null) => {

		delete obj.id;

		var doc = firebase.firestore().collection('planograms').doc();
		obj.id = doc.id;
		obj.name = obj.name + ' - copie';
		doc.set(obj).then(() => {
			if (callback !== null) callback(obj);
		}).catch((error) => {});

		return true;
	};


	const UpdatePlanogram = (id, data, user, callback = null) => {

		console.log('UpdatePlanogram', id, data, user);

		firebase.firestore().collection('planograms').doc(id).update({
			'__meta.update_date' : firebase.firestore.FieldValue.serverTimestamp(),
			'__meta.updated_by' : user.uid,
			...data
		}).then((doc) => {
			if (callback !== null) callback();
		}).catch((error) => {
			console.error('Error updating planograms: ', error);
		});
	}




	return(
		<ShopsContext.Provider value={{

			economic_models: economic_models,

			GetShop: GetShop,
			GetProductionTemplate: GetProductionTemplate,

			shop: shop,
			shops: shops,

			CreatePricelistGroup: CreatePricelistGroup,
			DuplicatePricelist: DuplicatePricelist,
			GetCurrentPriceList: GetCurrentPriceList,
			GetShopsByPricelist: GetShopsByPricelist,
			GetCurrentRateCard: GetCurrentRateCard,

			GetInvoice: GetInvoice,
			GetInvoices: GetInvoices,

			price_list: price_list,
			price_list_groups: price_list_groups,

			setCurrentShop : setCurrentShop,

			shop_unsold: shop_unsold,
			setShopUnsold: setShopUnsold,
			saveUnsold: saveUnsold,

			generateInvoice: generateInvoice,
			regenerateInvoice: regenerateInvoice,
			deleteInvoice: deleteInvoice,

			GetPlanogram: GetPlanogram,
			GetPlanograms: GetPlanograms,
			UpdatePlanogram: UpdatePlanogram,
			DuplicatePlanogram: DuplicatePlanogram
		}}>
			{children}
		</ShopsContext.Provider>
	);
}

export default React.memo(ShopsProvider);
