import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, IconButton, Button, FormControlLabel } from '@material-ui/core';
import { Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";

import TemplateBloc from './TemplateBloc';
import { GetPrice } from '.././Utils';
import { DateTime } from "luxon";


function ProductionTemplate(data) {


	const { checkUserRights } = useContext(UsersContext);
	const { products_by_sku } = useContext(ShakiContext);
	const { GetProductionTemplate, GetCurrentPriceList, GetCurrentRateCard } = useContext(ShopsContext);

	var { id, shop, pricelist_group_id } = data;

	var action = id === 'new' ? 'create' : 'update';

	const [current_template, setCurrentTemplate] = useState({});

	const [recipes, setRecipes] = useState({});
	const [pricelist, setPricelist] = useState({});
	const [rate_card, setRateCard] = useState({});

	var default_product_values = { days: { default: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false }};

	useEffect(() => {

		setPricelist(GetCurrentPriceList(pricelist_group_id, parseInt(DateTime.local().plus({ days: 7 }).toFormat('yyyyLLdd'))));
		setRateCard(GetCurrentRateCard(parseInt(DateTime.local().plus({ days: 7 }).toFormat('yyyyLLdd'))))

	}, []);

	useEffect(() => {

		if (Object.values(pricelist).length === 0) return;

		for (var sku in pricelist.prices) {

			if (products_by_sku[sku] === undefined) continue;

			if (pricelist.prices[sku].enabled === undefined || pricelist.prices[sku].enabled === false) continue;
			if (products_by_sku[sku].disabled) continue;
			if (products_by_sku[sku].sub_recipe) continue;

			var brand = (products_by_sku[sku].brand !== undefined) ? products_by_sku[sku].brand : 'Autres';
			if (recipes[brand] === undefined) recipes[brand] = {}

			var category = (products_by_sku[sku].category !== undefined) ? products_by_sku[sku].category : 'Autres';
			if (recipes[brand][category] === undefined) recipes[brand][category] = {}

			if (products_by_sku[sku].category !== undefined) {
				recipes[brand][products_by_sku[sku].category][sku] = products_by_sku[sku];
			} else {
				recipes[brand]['Autres'][sku] = products_by_sku[sku];
			}

			//default_product_values[sku] = 0;
		};

		setRecipes(recipes);

	}, [products_by_sku, pricelist]);


	const { register, handleSubmit, watch, control, reset } = useForm({
		defaultValues: current_template
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "list"
	});

	const [visible_tabs, setVisibleTabs] = useState({});

	const watchList = watch("list", []); // you can supply default value as second argument
	const getQuantity = (data) => {

		var ret = {}

		loop1:
		for (var i in data) {

			if (ret[i] === undefined) ret[i] = { total_products: 0, total_prices: 0 };

			loop2:
			for (var sku in data[i]) {

				if (sku === 'id' || sku === 'day' || sku === 'days') continue loop2;
				ret[i].total_products += parseInt(data[i][sku]);
				ret[i].total_prices += GetPrice(rate_card, pricelist, null, sku, parseInt(data[i][sku]), false);
			}
		}

		return ret;
	}
	var quantity = getQuantity(watchList);


	useEffect(() => {

		if (id === 'new') return;

		GetProductionTemplate(id, (doc) => {

			var template = doc.data();

			for (var i in template.list) {
				if (template.list[i].days !== undefined) continue;
				template.list[i].days = { default: true, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false };
			}

			reset(template);
			setCurrentTemplate(template);
		});

	}, [id]);


	const onSubmit = (raw_data) => {

		var raw_data_stringified = JSON.stringify(raw_data);
		var data = JSON.parse(raw_data_stringified);

		if (data.list === undefined) data.list = [];

		loop1:
		for (var i in data.list) {

			loop2:
			for (var sku in data.list[i]) {
				if (sku === 'id' || sku === 'day' || sku === 'days') continue loop2;
				data.list[i][sku] = parseInt(data.list[i][sku]);
			}
		}

		//console.log(data);
		if (action === 'create') {

			firebase.firestore().collection('production_templates').add(data).then(function(docRef) {

				console.log('Document written with ID: ', docRef.id);

				firebase.firestore().collection('production_templates').doc(docRef.id).update({
					id: docRef.id
				});

				firebase.firestore().collection('shaki_shops').doc(shop.id).update({
					production_template_id: docRef.id
				});

			}).catch(function(error) {
				console.error('Error adding document: ', error);
			});

		} else {

			firebase.firestore().collection('production_templates').doc(current_template.id).update(data).then(function() {
				console.log('update');
			});
		}
	}


	if (action === 'update' && Object.keys(current_template).length === 0) {
		return null;
	}

	return (
		<div style={{ padding: 20 }}>

				<form onSubmit={handleSubmit(onSubmit)}>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems : 'center', justifyContent : 'reverse', width: '100%', marginBottom: 50 }}>

						<Button type="button" variant="contained" size="small" color="default" onClick={() => append(default_product_values)} >
							Nouveau template
	      				</Button>

	      			</div>

					{fields.map((field, index) => (
						<div key={field.id} style={{ marginBottom: 25, width: 700, borderWidth: 1, borderColor: '#AF7AC5', borderStyle: 'solid', borderRadius: 16 }}>

							<div style={{ backgroundColor: '#AF7AC5', color: '#fff', padding: 5, paddingLeft: 10, borderRadius: 15 }}>

								<div style={{ display: 'flex', flexDirection: 'row', alignItems : 'center', justifyContent : 'space-between'}}>

									<div><b>Template {index + 1}</b></div>

									{quantity[index] !== undefined &&
										<div>{quantity[index].total_products} produit(s) | {Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(quantity[index].total_prices)}</div>
									}

									<IconButton aria-label="delete" onClick={(e) => {
										var obj = {};
										obj['tab_' + index] = (visible_tabs['tab_' + index] === undefined) ? true : !visible_tabs['tab_' + index];
										setVisibleTabs({...visible_tabs,...obj})
									}}>
										<ArrowDownwardIcon fontSize="inherit" />
									</IconButton>
								</div>


								<div style={{ display : 'flex', flexDirection: 'row', alignItems : 'center' }}>

									<Controller
									defaultValue={field.days.default}
									name={`list[${index}].days.default`}
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label='def'
											/>
										}
									/>

									<Controller
									defaultValue={field.days.monday}
									name={`list[${index}].days.monday`}
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label='Lun'
											/>
										}
									/>

									<Controller
									defaultValue={field.days.tuesday}
									name={`list[${index}].days.tuesday`}
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label='Mar'
											/>
										}
									/>

									<Controller
									defaultValue={field.days.wednesday}
									name={`list[${index}].days.wednesday`}
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label='Mer'
											/>
										}
									/>

									<Controller
									defaultValue={field.days.thursday}
									name={`list[${index}].days.thursday`}
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label='Jeu'
											/>
										}
									/>

									<Controller
									defaultValue={field.days.friday}
									name={`list[${index}].days.friday`}
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label='Ven'
											/>
										}
									/>

									<Controller
									defaultValue={field.days.saturday}
									name={`list[${index}].days.saturday`}
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label='Sam'
											/>
										}
									/>

									<Controller
									defaultValue={field.days.sunday}
									name={`list[${index}].days.sunday`}
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label='Dim'
											/>
										}
									/>

								</div>
							</div>


							<div style={{ margin: 10, display: (visible_tabs['tab_' + index] === undefined || !visible_tabs['tab_' + index]) ? 'none' : 'block' }}>

								<TemplateBloc field={field} index={index} register={register} recipes={recipes} />

								<Button variant="contained" color="primary" size="small" onClick={() => remove(index)} style={{ marginTop: 20 }}>
									Supprimer le template
								</Button>
							</div>

						</div>
					))}

					<div>
						<Button disabled={!checkUserRights('production_templates')} variant="contained" color="primary" type='submit'>
							Enregistrer
						</Button>
					</div>
				</form>

		</div>
	);
}

export default React.memo(ProductionTemplate);

