import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button } from '@material-ui/core';

import { UsersContext } from "../store/UsersProvider";

import { GetUnpaidInvoices, GetOrders } from '.././Store';
import { DateTime } from "luxon";

function Home() {

	const { user } = useContext(UsersContext);

	const [unpaid_invoices, setUnpaidInvoices] = useState([]);
	const [unsend_invoices, setUnsendInvoices] = useState([]);
	const [orders, setOrders] = useState([]);
	const [orders_preparation, setOrdersPreparation] = useState([]);


	useEffect(() => {

		var treatment = (querySnapshot) => {

			var list = [];
			var list_unsend = [];
			querySnapshot.forEach(doc => {
				list.push(doc.data());
				if (doc.data().emailed.length === 0) {
					list_unsend.push(doc.data());
				}
			});

			setUnpaidInvoices(list);
			setUnsendInvoices(list_unsend);
		}

		return GetUnpaidInvoices(treatment);
	}, []);


	useEffect(() => {

		return GetOrders(DateTime.local(), (querySnapshot) => {

			var list = [];
			var list_to_prepared = [];
			querySnapshot.forEach(doc => {
				list.push(doc.data());
				if (doc.data().prepared_at !== undefined) {
					list_to_prepared.push(doc.data());
				}
			});

			setOrders(list);
			setOrdersPreparation(list_to_prepared);
		});

	}, []);


	return (
		<div style={{ padding: 20 }} >
			<h2 style={{ paddingBottom: 40 }}>Bienvenue {user.first_name}</h2>
			<div style={{ display: 'flex' }}>
				<Card title={'Commandes préparées'} date={"Aujourd'hui"} value={orders_preparation.length + '/' + orders.length} />
				<Card title={'Factures impayées'} date={"Aujourd'hui"} value={unpaid_invoices.length} />
				<Card title={'Factures à envoyer'} date={"Aujourd'hui"} value={unsend_invoices.length} />
			</div>
		</div>
	);
}

export default React.memo(Home);

const Card = ({title, date, value}) => {

	const classes = useStyles();

	return (
		<Paper className={classes.root} >
			<div>

				<div className={classes.card_header}>
					<Typography className={classes.title} color="textSecondary" gutterBottom>
						{title}
					</Typography>
					<div className={classes.card_date}>
						{date}
					</div>
				</div>

				<Typography className={classes.card_value} variant="h2" component="h2">
					{value}
				</Typography>

			</div>
			<div>
				<Button variant='outlined' size="small">voir</Button>
			</div>
		</Paper>
		);
}

const useStyles = makeStyles({
	root: {
		width: 270,
		padding : 16,
		marginRight : 15,
	},
	card_header : {
		display : 'flex',
		justifyContent : 'space-between'

	},
	card_date : {
		borderRadius : 6,
		backgroundColor : 'rgb(71, 130, 218)',
		color : 'white',
		paddingLeft : 6,
		paddingRight : 6,
		fontSize : 11,
		height : 'fit-content'
	},
	card_value : {
		fontWeight : 500,
		fontSize : 50,
		paddingBottom : 20,
		paddingTop : 20
	},

	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: 14,
		fontWeight : 400
	},
	pos: {
		marginBottom: 12,
	},
});
