import React from 'react';

import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

import { Select, Typography, Breadcrumbs, Button, Avatar } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";

import Resizer from 'react-image-file-resizer';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { ShakiContext } from "../store/ShakiProvider";


function LunaAvatar() {

	const { CreateLunaAvatar, UpdateLunaAvatar, GetLunaAvatar, setOpenSnackBar } = React.useContext(ShakiContext);

	const classes = useStyles();
	var history = useHistory();

	var { avatar_id } = useParams();
	var action = avatar_id === 'new' ? 'create' : 'update';

	const [current_avatar, setCurrentAvatar] = useState({});
	const [image, setImage] = useState(null);

	var title = (current_avatar.name !== undefined) ? current_avatar.name : 'Nouveau';

	const { register, handleSubmit, errors, reset } = useForm({});


	useEffect(() => {

		if (avatar_id === 'new') return;

		GetLunaAvatar(avatar_id, (doc) => {

			var data = doc.data();
			reset(data);

			if (data.image !== undefined) setImage(data.image);

			setCurrentAvatar(data);
		});

	}, [avatar_id]);


	const onSubmit = (raw_data) => {

		var data = JSON.parse(JSON.stringify(raw_data));

		if (image !== null) data.image = image;

	console.log(data);

		if (action === 'create') {
			CreateLunaAvatar(data, (id) => {
				setOpenSnackBar('Sauvegardé')
				history.push('/luna_avatars/' + id);
			});
		} else {
			UpdateLunaAvatar(avatar_id, data, (id) => {
				setOpenSnackBar('Sauvegardé')
			});
		}
	};


	const fileChangedHandler = (event) => {

		if (event.target.files[0]) {
			Resizer.imageFileResizer(event.target.files[0], 1200, 1200, 'JPEG', 95, 0, uri => {
				setImage(uri)
			}, 'base64');
		}
	}


	return (
		<div>

			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
				<Link to='/'>Dashboard</Link>
				<Link to='/luna_avatars'>Avatars Luna</Link>
				<Typography>{title}</Typography>
			</Breadcrumbs>

			<h2 style={{ paddingBottom : 40 }}>{title}</h2>

			<div style={{ width: 700 }}>
				<form onSubmit={handleSubmit(onSubmit)}>

					<div style={{ paddingBottom: 40 }}>

						<div style={{ display: 'flex', paddingBottom: 10 }}>

							<TextField
								required
								inputRef={register({
									required: true
								})}
								id='name'
								name='name'
								label='Nom'
								style={{ width: '100%' }}
								error={errors.name ? true : false}
							/>
						</div>


						<div style={{ display: 'flex', paddingBottom: 10 }}>
							<FormControl className={classes.formControl} style={{ width: 200 }}>
								<InputLabel htmlFor="brand">Gender</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'gender',
										id: 'gender',
									}}
								>
									<option value='M'>Male</option>
									<option value='F'>Female</option>
								</Select>
							</FormControl>
						</div>

					</div>

					<div style={{ paddingBottom: 40 }}>

						<Typography variant='h6' noWrap style={{ paddingBottom: 20 }}><b>Visuels :</b></Typography>

						<div>
							<label htmlFor='image' style={{ marginRight: 20 }}>
								<input id='image' type='file' onChange={fileChangedHandler} style={{ display: 'none' }}/>
								<Avatar
									src={image}
									style={{ width: 200, height: 200 }}
									component='span'
								/>
							</label>
						</div>
					</div>

					<div>
						<Button variant="contained" color="default" type='submit'>
							Enregistrer
						</Button>
					</div>

				</form>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default React.memo(LunaAvatar);


