import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TablePagination, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, TextField, Avatar} from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";

import { ShakiContext } from "../store/ShakiProvider";
import { GetImagePath } from '.././Utils';
import { ClientsContext } from '../store/ClientsProvider';

import ClientHeader from './ClientHeader';

function ClientShops() {

	const { getRemoteConf, tags } = useContext(ShakiContext);
	const { getShopsByClient } = useContext(ClientsContext);

    var { client_id } = useParams();

	const classes = useStyles();

    const [tab_value, setTabValue] = useState(1);

	const [list, setList] = useState([]);
	const [filter, setFilter] = useState('');
	const [filtered_list, setFilteredList] = useState([]);
	const [cache, setCache] = useState({});

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(200);


	useEffect(() => {

		getShopsByClient(client_id, (shops) => {
			setList(shops);
		});

	}, [client_id]);


	useEffect(() => {

		var search_lower_case = filter.toLowerCase();


			var new_list = list;
			if (search_lower_case !== '') {

				var new_list = list.filter((row) => {
					return row.search_index.search(search_lower_case) !== -1
				});
			};

			new_list.sort((a, b) => (a.name < b.name) ? -1 : 1);

			//cache[search_lower_case] = new_list;
			//setCache({...cache});

		setFilteredList(new_list);

	}, [list, filter]);


	return (
		<div>

			<ClientHeader client_id={client_id} tab_value={tab_value} />

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell style={{ width : 60 }}></StyledTableCell>
							<StyledTableCell style={{ width : 140 }}>Key</StyledTableCell>
							<StyledTableCell>Nom</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{filtered_list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
						<StyledTableRow key={row.id}>

							<StyledTableCell style={{ width : 60 }}>
								<Link to={'/shops/' + row.id}>
									<Avatar src={GetImagePath(row.images, 'main')} style={{ width: 40, height: 40 }}>{row.name.substr(0, 1)}</Avatar>
								</Link>
							</StyledTableCell>

							<StyledTableCell component="th" scope="row" style={{ width : 140 }}>
								<Link to={'/shops/' + row.id}>{row.key}</Link>
							</StyledTableCell>

							<StyledTableCell>
							<div style={{ display : 'flex', flexDirection: 'row', alignItems: 'center' }}>

								<div style={{ marginRight: 10 }}>
									{row.open !== undefined && row.open === true ? '🟢' : '🔴'} {row.name}
								</div>

								{row.tags !== undefined &&
								<div style={{ display : 'flex', flexDirection: 'row', alignItems: 'center' }}>
									{row.tags.map((key) => (
										<div key={key} style={{ marginRight: 5, padding: 5, backgroundColor: '#FDFDFD', borderStyle: 'solid', borderRadius: 10, borderWidth: 1, borderColor: '#DDD' }}>
											{tags[key].title}
										</div>
									))}
								</div>
								}

							</div>
							</StyledTableCell>
						</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[50, 100, 200]}
				component="div"
				count={filtered_list.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(event) => {
					setRowsPerPage(parseInt(event.target.value, 10));
					setPage(0);
				}}
			/>
		</div>
	);
}

export default React.memo(ClientShops);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
