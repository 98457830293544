import React from 'react';
import { useState, useContext, useEffect } from 'react';
import {BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";

import clsx from 'clsx';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import { ListItem, Divider, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { algoliasearch } from 'algoliasearch';
import { DateTime } from "luxon";


function Search() {

	const [search_input, setSearchInput] = useState('');
	const [search_result, setSearchResult] = useState([]);
	const [search_focus, setSearchFocus] = useState(true);

	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const client = algoliasearch('GA6F55QHO8', 'f334a49593618bbe012c212e649ede78');


	useEffect(() => {

		if (search_input === '') {
			setSearchResult([]);
			return;
		};

		const response = client.search({
			requests: [
				{ indexName: 'products', query: search_input, facetFilters: ['sub_recipe:false'], hitsPerPage: 5 },
				{ indexName: 'shops', query: search_input, hitsPerPage: 5 },
				{ indexName: 'orders', query: search_input, hitsPerPage: 5 }
			],
		}).then((response) => {
			setSearchResult(response.results);
		});

	}, [search_input]);


	const handleInputFocus = () => {
		setSearchFocus(true);
	};


	const handleInputBlur = () => {
		setSearchFocus(false);
	};


	const handleClick = (url) => {
		history.push(url);
	};


	const ResultShopItem = (data) => {
		return (
			<ListItem onMouseDown={() => history.push('/shops/' + data.objectID)} style={{ padding: 5, flexDirection: 'column' }} button component={Link} to={'/shops/' + data.objectID}>
				<div style={{ width: '100%', fontSize: 14, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} dangerouslySetInnerHTML={{__html: data._highlightResult.name.value}}></div>
			</ListItem>
		);
	};


	const ResultProductItem = (data) => {
		return (
			<ListItem onMouseDown={() => history.push('/products/' + data.objectID)} style={{ padding: 5, flexDirection: 'column' }} button component={Link} to={'/products/' + data.objectID}>
				<div style={{ width: '100%', fontSize: 14, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} dangerouslySetInnerHTML={{__html: data._highlightResult.name.value}}></div>
				<div style={{ width: '100%', fontSize: 11, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}  dangerouslySetInnerHTML={{__html: data._highlightResult.sku.value + ' | ' + data._highlightResult.brand.value}} />
			</ListItem>
		);
	};


	const ResultOrderItem = (data) => {
		return (
			<ListItem onMouseDown={() => history.push('/order/' + data.objectID)} style={{ padding: 5, flexDirection: 'column' }} button component={Link} to={'/order/' + data.objectID}>
				<div style={{ width: '100%', fontSize: 14, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} dangerouslySetInnerHTML={{__html: data._highlightResult.shop.name.value + ' - livr le ' + DateTime.fromISO(data.day).toFormat('dd/LL/yyyy')}}></div>
				<div style={{ width: '100%', fontSize: 11, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{data.edi_refence}</div>
			</ListItem>
		);
	};


	return (
		<div className={classes.search}>

			<div className={classes.searchIcon}>
				<SearchIcon />
			</div>

			<InputBase
				placeholder='Search…'
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				inputProps={{ 'aria-label': 'search' }}
				onChange={(e) => {
					setSearchInput(e.target.value)
				}}
				onFocus={handleInputFocus}
				onBlur={handleInputBlur}
			/>

			<div style={{ position: 'absolute', width: '100%', display: search_focus ? 'block' : 'none' }} >

				{search_result.map((block, index) => (
					<>
					{block.hits.length > 0 &&
					<div key={block.index} style={{ marginLeft: 40, borderStyle: 'solid', borderColor: '#DDD', borderWidth: 1, backgroundColor: 'white' }}>

						<div style={{ backgroundColor: '#333', color: 'white', padding: 5, fontSize: 12 }}>
							{block.index}
						</div>

						<div>
						{block.hits.map((row, index2) => (
							<div key={row.objectID}>
							{block.index === 'products' &&
								<ResultProductItem {...row} />
							}
							{block.index === 'shops' &&
								<ResultShopItem {...row} />
							}
							{block.index === 'orders' &&
								<ResultOrderItem {...row} />
							}
							</div>
						))}
						</div>
					</div>
					}
					</>
				))}

			</div>

		</div>
	);
};

export default React.memo(Search);


const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
	ListItem: {
		fontSize: 16,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto'
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '15ch',
			'&:focus': {
				width: '30ch',
			},
		},
	},
}));

