import React from 'react';
import firebase from "firebase/compat/app";

import { useState, useEffect, useContext } from 'react';
import { TextField, Typography, Breadcrumbs, Button, IconButton, Select, InputLabel, FormControl} from '@material-ui/core';

import { Link } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';


import LuxonUtils from '@date-io/luxon';
import { DateTime } from "luxon";

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";

import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';

import { setRoutesConf, getRoutesConfs } from '.././Store';
import { WeekDays, GetOrderKey, GetLocalStorage, SetLocalStorage } from '.././Utils';

function ConfMap() {

	var { getRemoteConf } = useContext(ShakiContext);
	var { shops } = useContext(ShopsContext);


	const [cards, setCards] = useState([]);
	const [list, setList] = useState([]);
	const [filter_hash, setFilterHash] = useState({});

	const [selected_day, setSelectedDay] = useState(0);

	const [delivery_operators, setDeliveryOperators] = useState({});

	const [city_filter, setCityFilter] = useState('Paris');
	const [operator_filter, setOperatorFilter] = useState('');


	useEffect(() => {
		setDeliveryOperators(getRemoteConf('delivery_operators'));
	}, []);


	useEffect(() => {

		if (shops === null) return;

		return getRoutesConfs((docs) => {

			var c = [];
			docs.forEach((doc) => {

				var shift = doc.data();
				shift.sort_key = shift.national + shift.city + shift.name;

				if (c[parseInt(shift.day) - 1] === undefined) c[parseInt(shift.day) - 1] = [];
				c[parseInt(shift.day) - 1].push(shift);
			});

			for (var day in c) {
				c[day].sort((a, b) => a.sort_key > b.sort_key ? 1 : -1);
			};

			setCards(c);
		});

	}, [shops]);


	useEffect(() => {

		if (cards.length === 0) return;

		var new_list = [];
		var filter_hash = {};

		for (var row of cards[selected_day]) {

			if (city_filter !== '' && city_filter !== row.city) filter_hash[row.id] = true;
			if (operator_filter !== '' && operator_filter !== row.operator) filter_hash[row.id] = true;

			new_list.push(row);
		};

		setFilterHash(filter_hash);
		setList(new_list);

	}, [selected_day, cards, city_filter, operator_filter]);


	const switchDay = (day) => {
		setSelectedDay(day)
	};


	var colors = ['#50808e', '#654f6f', '#cce2a3', '#0a122a', '#9a031e', '#7adfbb', '#321325', '#cb793a', '#fcdc4d', '#804e49', '#4e6e58',
		'#c532c9', '#96a8fe', '#3b196a', '#23a24e', '#a9be37', '#5442da', '#e38a74', '#ec91c8', '#a44748', '#57003a', '#b884f0', '#3156f8',
		'#7db3ca', '#eacc15', '#1f7b0e', '#3e79b6', '#1ef084', '#cf135f', '#f86245', '#84e2aa', '#ac6660'];


	return (
		<div>

			<div style={{ width: '100%', position: 'fixed', left: 0, top: 60, backgroundColor: "white", zIndex: 1000, borderStyle: "dashed", borderWidth: 0, borderWidthBottom: 0.5, borderColor: '#DDD' }}>

				<div style={{ marginLeft: 220, marginTop: 20 }}>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>

						<div style={{ display: 'flex', width: 900, marginBottom: 20, alignItems: 'center' }}>

							<div style={{ display : 'flex' }}>
								{WeekDays.map((day, i) => (
								<div key={'week_days_' + i} style={selected_day === i ? { padding : 5, backgroundColor : '#000', color : '#FFF', borderRadius : 5, marginRight: 10 } : { padding : 5, backgroundColor : '#F0F0F0', color : '#000', borderRadius : 5, marginRight: 10 }} onClick={(e) => switchDay(i)}>
									{day}
								</div>
								))}
							</div>

							<div style={{ display: 'flex' }}>
								<FormControl style={{ width: 200, marginRight: 10 }} >
									<InputLabel htmlFor={'city_filter'}>Filtrer par ville</InputLabel>
									<Select
										native
										value={city_filter}
										onChange={(e) => {setCityFilter(e.target.value)}}
										inputProps={{
											name: 'city',
											id: 'city_filter',
										}}
										InputLabelProps={{
											shrink: true,
										}}
									>
										<option value=''>-</option>
										<option>Paris</option>
										<option>Lyon</option>
										<option>Marseille</option>
										<option>Aix-en-Provence</option>
										<option>Nice / Cannes</option>
									</Select>
								</FormControl>
							</div>

							<div style={{ display: 'flex' }}>
								<FormControl style={{ width: 200 }} >
									<InputLabel htmlFor={'operator'}>Filtre par opérateur</InputLabel>
									<Select
										native
										value={operator_filter.operator}
										onChange={(e) => {setOperatorFilter(e.target.value)}}
										inputProps={{
											name: 'operator',
											id: 'operator_fitler',
										}}
										InputLabelProps={{
											shrink: true,
										}}
									>
										<option value=''></option>
										{Object.entries(delivery_operators).map((operator, i) => (
											<option value={operator[0]}>{operator[1].name}</option>
										))}
									</Select>
								</FormControl>
							</div>

			    		</div>

					</div>

	    		</div>
    		</div>

    		<div style={{ display : 'flex', alignItems : 'center', marginTop: 60 }}>
			<LoadScript googleMapsApiKey="AIzaSyAezkTsefd38GD4f13zlQ92C4P9I478I0g">

				<GoogleMap
					mapContainerStyle={{
						width: '100%',
						height: '600px'
					}}
					center={{
						lat: 48.866667,
						lng: 2.333333
					}}
					zoom={12}
				>

					{list.map((conf, i) => {

						if (filter_hash[conf.id]) return;

						var coordinnates = [];
						for (var row of conf.list) {
							coordinnates.push(shops[row.shop_id].location);
						};

						console.log(coordinnates)

						return (
							<>
								<Polyline
									options={{
										strokeColor: colors[i],
										strokeWeight: 3,
									}}
									path={coordinnates}
								/>
								{conf.list.map((row, j) => (
									<Marker
										icon={{
											path: 'M-5,0a5,5 0 1,0 10,0a5,5 0 1,0 -10,0',
											fillColor: colors[i],
											fillOpacity: 0.5,
											scale: 2,
											strokeColor: colors[i],
											strokeWeight: 1,
										}}
										label={String(j + 1)}
										title={shops[row.shop_id].name}
										position={shops[row.shop_id].location}
									/>
								))}
							</>
						)}
					)}

				</GoogleMap>
			</LoadScript>
			</div>
		</div>
	);
}

export default React.memo(ConfMap);

