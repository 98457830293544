import firebase from "firebase/compat/app"
import "firebase/compat/auth";

//import { initializeApp } from "firebase/app";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

import Nav from './Nav';

import TimeProvider from './store/TimeProvider';
import UsersProvider from './store/UsersProvider';
import ShakiProvider from './store/ShakiProvider';
import ShopsProvider from './store/ShopsProvider';
import ClientsProvider from './store/ClientsProvider';
import OrdersProvider from './store/OrdersProvider';
import ToursProvider from './store/ToursProvider';


function App() {

	const app = firebase.initializeApp({
		apiKey: "AIzaSyBYTXFbp_p3fV9O_u0wXWMHkVtXNTGPUbk",
		authDomain: "shakishaki-243509.firebaseapp.com",
		databaseURL: "https://shakishaki-243509.firebaseio.com",
		projectId: "shakishaki-243509",
		storageBucket: "shakishaki-243509.appspot.com",
		messagingSenderId: "200499339726",
		appId: "1:200499339726:web:b17d31c6274981c4e74b9f",
		measurementId: "G-JSQPM27NYL"
	});

	const auth = getAuth(app);

	if (isSignInWithEmailLink(auth, window.location.href)) {

		var email = window.localStorage.getItem('emailForSignIn');
		if (!email) {
			email = window.prompt('Please provide your email for confirmation');
		};

		// The client SDK will parse the code from the link for you.
		signInWithEmailLink(auth, email, window.location.href).then(function(result) {
			//window.localStorage.removeItem('emailForSignIn');
			console.log('r', result);
		}).catch(function(error) {
			console.log(error);
		});
	};

	return (
		<UsersProvider>
			<TimeProvider>
				<ShakiProvider>
					<ClientsProvider>
						<ShopsProvider>
							<OrdersProvider>
								<ToursProvider>
									<Nav/>
								</ToursProvider>
							</OrdersProvider>
						</ShopsProvider>
					</ClientsProvider>
				</ShakiProvider>
			</TimeProvider>
		</UsersProvider>
	);
};

export default App;
