import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TablePagination, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, IconButton, Button} from '@material-ui/core';
import { Link, useParams } from "react-router-dom";

import { UsersContext } from "../store/UsersProvider";


import { getBankTransactions } from '.././Store';
import { Months } from '.././Utils';
import { DateTime } from "luxon";

function Balances() {

	const { user } = useContext(UsersContext);

	const [transactions, setTransactions] = useState([]);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(300);
	const [selected_month, setSelectedMonth] = useState(DateTime.local().month);
	const [selected_year, setSelectedYear] = useState(DateTime.local().year);
console.log('selected_year', selected_year)
	const classes = useStyles();

	useEffect(() => {

		var treatment = (querySnapshot) => {

			var list = [];
			querySnapshot.forEach((doc) => {
				list.push(doc.data());
			});

			setTransactions(list);
		}

		return getBankTransactions(selected_year, selected_month, treatment);

	}, [selected_year, selected_month]);


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};


	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const check = (text) => {

		var matches = [...text.matchAll(/\bF?(2023|2024)-? ?([0-9]{3,5})\b/gm)];

		for (var match of matches) {
			text = text.replace(match[0], '<b style="color: red">' + match[0] + '</b>')
		};


		if (matches.length === 0) {
			var matches = [...text.matchAll(/\bF?()([0-9]{3,5})\b/gm)];
			for (var match of matches) {
				if (match[0] === '2024' || match[0] === '2023') continue;
				text = text.replace(match[0], '<b style="color: green">' + match[0] + '</b>')
			};
		}


		return (<div dangerouslySetInnerHTML={{ __html: text }}></div>);
	}

	return (
		<div style={{ padding: 20 }} >

			<div style={{ display : 'flex', alignItems: 'center', justifyContent : 'space-between', gap: 5, paddingBottom: 10 }}>

				{[2023, 2024, 2025].map((year, i) => (
					<div key={'year_' + i} style={(selected_year) === year ? { padding: 10, backgroundColor: '#000', color: '#FFF', borderRadius: 5 } : { padding: 10, backgroundColor: '#F0F0F0', color: '#000', borderRadius: 5 }} onClick={(e) => setSelectedYear(i)}>
						{year}
					</div>
				))}

				<div style={{ width: 1, height: 30, marginLeft: 10, marginRight: 10, backgroundColor: 'black' }} />

				{Months.map((month, i) => (
					<div key={'month_' + i} style={(selected_month) === i+1 ? { padding: 10, backgroundColor: '#000', color: '#FFF', borderRadius: 5 } : { padding: 10, backgroundColor: '#F0F0F0', color: '#000', borderRadius: 5 }} onClick={(e) => setSelectedMonth(i+1)}>
						{month}
					</div>
				))}
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>

							<StyledTableCell style={{ width: '12%' }}>Date</StyledTableCell>
							<StyledTableCell style={{ width: '12%' }}>Banque</StyledTableCell>
							<StyledTableCell style={{ width: '30%' }}>From</StyledTableCell>
							<StyledTableCell style={{ width: '30%' }}>Label</StyledTableCell>
							<StyledTableCell style={{ width: '10%' }}>Balance identitée</StyledTableCell>
							<StyledTableCell style={{ width: '10%' }} align="right">Montant</StyledTableCell>

						</TableRow>
					</TableHead>

					<TableBody>
						{transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (

							<>
							<StyledTableRow key={row.key} style={{ textDecorationLine: row.deleted ? 'line-through' : 'none' }}>

								<StyledTableCell component="th" style={{ fontWeight: row.invoices !== undefined ? '550' : '400' }} scope="row">{DateTime.fromISO(row.date).toFormat('dd/LL/yyyy')}</StyledTableCell>
								<StyledTableCell component="th" style={{ fontWeight: row.invoices !== undefined ? '550' : '400' }} scope="row">{row.bank}</StyledTableCell>
								<StyledTableCell component="th" style={{ fontWeight: row.invoices !== undefined ? '550' : '400' }} scope="row">{row.from}</StyledTableCell>
								<StyledTableCell component="th" style={{ fontWeight: row.invoices !== undefined ? '550' : '400' }} scope="row">{check(row.label)}</StyledTableCell>
								<StyledTableCell component="th" style={{ fontWeight: row.invoices !== undefined ? '550' : '400' }} scope="row">{row.balance_key}</StyledTableCell>
								<StyledTableCell component="th" style={{ fontWeight: row.invoices !== undefined ? '550' : '400' }} scope="row" align="right">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.amount)}</StyledTableCell>

							</StyledTableRow>

							{row.invoices !== undefined &&
								<>
								{row.invoices.map((row2) => (

									<StyledTableRow key={row2.number}>

										<StyledTableCell component="th" style={{ paddingTop: 5, paddingBottom: 5 }} scope="row"></StyledTableCell>
										<StyledTableCell component="th" style={{ paddingTop: 5, paddingBottom: 5 }} scope="row"></StyledTableCell>
										<StyledTableCell component="th" style={{ paddingTop: 5, paddingBottom: 5 }} scope="row"></StyledTableCell>
										<StyledTableCell component="th" style={{ paddingTop: 5, paddingBottom: 5 }} scope="row"></StyledTableCell>
										<StyledTableCell component="th" style={{ paddingTop: 5, paddingBottom: 5 }} scope="row">{row2.year}-{row2.number}</StyledTableCell>
										<StyledTableCell component="th" style={{ paddingTop: 5, paddingBottom: 5 }} scope="row" align="right">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row2.amount)}</StyledTableCell>

									</StyledTableRow>

								))}
								</>
							}
							</>

						))}
					</TableBody>
				</Table>

			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[50, 100, 200]}
				component="div"
				count={transactions.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

		</div>
	);
}

export default React.memo(Balances);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
});
